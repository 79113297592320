import React, {Component} from 'react';

import Header from './header'
import Scaler from './scaler'
import Editor from './editor'
import Footer from './footer'



class Layout extends Component { // eslint-disable-line react/prefer-stateless-function

  render() {

    const { props }   = this

    return (

        <>
            <Header />

            <div className="uk-flex">
                
                <div className="scaler-sidebar-left uk-section uk-section-primary uk-padding-remove-vertical uk-light uk-overflow-auto" uk-height-viewport="offset-top: true">
                    <Scaler {...props} />
                </div>

                <Editor />

            </div>
            <Footer />
        </>
    );
  }
}

export default Layout

