import React, { Component } from 'react'
import elementData from  './../config/index.json'

const elementJson  =  elementData.elements;

export default class view extends Component {
    render() {
        return (
            <div>
                <div id="modal-add-element" className="uk-flex-top scaler-modal" uk-modal="true">
                    <div className="uk-modal-dialog scaler-modal-dialog uk-margin-auto-vertical">
                        <button className="uk-modal-close-default" type="button" uk-close="true"></button>
                        <div className="uk-modal-header">
                            <h2 className="uk-modal-title uk-text-uppercase">Choose an Element</h2>
                        </div>
                        <div className="uk-modal-body">
                            <div className="uk-grid-small uk-child-width-1-6 scaler-element-list" uk-grid="true">
                                {
                                    this.elements(elementJson)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**
     * build elements list using json
     * 
     * @param {object} blocks 
     * 
     * @return {HTMLElement}
     */
    elements(blocks)
    {
        if( blocks )
        {
            return blocks.map((element, i) => {

                return (
                    <div key={element.data.id} id={element && element.id} onClick={(el) => this.handleEvents(el, element)}>
                        <div className="uk-border-rounded uk-text-center scaler-element" >
                            <div className="scaler-element-info uk-flex uk-flex-middle uk-flex-center uk-flex-column">
                                <div className="scaler-icon material-icons scaler-56">{element.data.title}</div>
                                <div className="scaler-element-label uk-text-uppercase">{element.data.text}</div>
                            </div>
                        </div>
                    </div> 
                )
                
            }, this)
        }
        return false
    }

    /**
     * 
     * @param {*} el 
     * @param {*} element 
     */
    handleEvents(el, element)
    {
        el.preventDefault()

        const { props }     = this

        props.handler.addElement( props, element )
    }
}
