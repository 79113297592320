import React, { Component } from 'react'

/** global color component */
import Color from './colors'

/** global typography component */
import Typography  from  './typography'

/** global headline component */
import Headline from './headlines'

/** global button component */
import Button from  './buttons'

/** global image component */
import Image  from  './images'

/** global link component */
import Link  from  './links'

export default class styles extends Component {

    render() {

        const { props } = this
        const { styleJson } = props

        return (
            <>
                <div className="scaler-sidebar-heading uk-padding-small scaler-padding-small uk-text-uppercase uk-flex uk-flex-middle uk-flex-between">
                    { styleJson && styleJson.title }
                    <a href="/" className="uk-flex uk-flex-middle">
                        <span className="material-icons scaler-12">arrow_back_ios</span>
                        <small id={props.constants.PANELS.SCALER} onClick={(el) => props.handler.switchPanel(el, props) } >{ styleJson && styleJson.back }</small>
                    </a>
                </div>
                    
                <ul uk-accordion="multiple: true" className="scaler-accordion">

                    <Color {...props} />
                    <Typography {...props} />
                    
                </ul>
                <div className="scaler-sidebar-heading uk-padding-small scaler-padding-small uk-text-uppercase">Component Styles</div>
                <ul uk-accordion="multiple: true" className="scaler-accordion">
                    <Headline {...props} />

                    <Button {...props} />

                    <Image {...props} />

                    <Link {...props} />
                </ul>
            </>
        )
    }
}
