import React, { Component } from 'react'
import Content from './content'
import Layout from './layout'
import Advance from './advance'

export default class Link extends Component {

    render() {

        const { props } = this

        return (
            <>
                <div className="scaler-sidebar-heading uk-padding-small scaler-padding-small uk-text-uppercase uk-flex uk-flex-middle uk-flex-between">
                    Button Settings
                    <a href="/" className="uk-flex uk-flex-middle"><span className="material-icons scaler-12">arrow_back_ios</span>
                        <small id={props.constants.PANELS.SCALER} onClick={(el) => props.handler.switchPanel(el, props) }>Page Blueprint</small>
                    </a>
                </div>
                <ul uk-accordion="true" className="scaler-accordion">
                     <Content {...props} />

                     <Layout />

                     <Advance />
                </ul>
            </>
        )
    }
}
