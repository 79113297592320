import React, { Component } from 'react'
import JsonArray from  './../modal/index.json'

export default class View extends Component {

    /**
     * container modal initialize
     * 
     * @param {*} NA
     * 
     * @return {*} renderedHtml
     */
    render() {

        const { blocks }    = JsonArray
        
        return (
            <div>
                <div id={JsonArray && JsonArray.modal} className="uk-flex-top scaler-modal" uk-modal="true">
                    <div className="uk-modal-dialog scaler-modal-dialog uk-margin-auto-vertical">
                        <button className="uk-modal-close-default" type="button" uk-close="true"></button>
                        <div className="uk-modal-header">
                            <h2 className="uk-modal-title uk-text-uppercase">{JsonArray && JsonArray.title}</h2>
                        </div>
                        <div className="uk-modal-body">
                            <div className="uk-grid-small uk-child-width-1-6 scaler-element-list" uk-grid="true">
                               
                                {
                                   this.containers(blocks) 
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**
     * build containers list using json
     * 
     * @param {object} blocks 
     * 
     * @return {HTMLElement}
     */
    containers(blocks)
    {
        if( blocks.containers )
        {
            return blocks.containers.map((elements, i) => {

                return (elements.data).map((element, i) => {

                    return (
                        <div key={i} id={element && element.id} onClick={(el) => this.handleEvents(el, element)}>
                            <div className="uk-border-rounded uk-text-center scaler-element">
                                <div className="scaler-element-info uk-flex uk-flex-middle uk-flex-center uk-flex-column">
                                    <div className="scaler-icon material-icons scaler-56"></div>
                                    <div className="scaler-element-label uk-text-uppercase">{element.title}</div>
                                </div>
                            </div>
                        </div>  
                    )
                })
            }, this)
        }
        return false
    }

    /**
     * 
     * @param {*} el 
     * @param {*} element 
     */
    handleEvents(el, element)
    {
        el.preventDefault()

        const { props }     = this

        props.handler.addContainer( props, element)
    }
}
