import React, { Component } from 'react';
import propTypes from 'prop-types'
import {saveJson, updateItems, switchPanel, stylePanel} from './../redux/actions/listActions'

import { connect } from 'react-redux';
import Layout from './../../component/layouts/layout'

class Index extends Component{ 
 
   render(){

      const {props} = this
      
      return(
        <Layout {...props} />
      );
   }
}

/** validate */
Index.propTypes = {
   saveJson: propTypes.func.isRequired,
   updateItems: propTypes.func.isRequired,
   switchPanel: propTypes.func.isRequired,
   stylePanel: propTypes.func.isRequired
}

const mapStateToProps = state => ({
   
   localStorage: state.dataPack.localStorage,
   jsonData: state.dataPack.jsonData,
   styleJson: state.dataPack.styleJson,
   handler: state.dataPack.event,
   helper: state.dataPack.helper,
   items: state.dataPack.items, 
   constants: state.dataPack.constants
})

export default connect (mapStateToProps, { saveJson, updateItems, switchPanel, stylePanel } )( Index )