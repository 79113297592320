import React, { Component } from 'react'

export default class Layout extends Component {
    render() {
        return (
            <>
                 <li>
                        <a className="uk-accordion-title uk-padding-small scaler-padding-small uk-h4 uk-text-bold scaler-heading" href="/">Layout</a>
                        <div className="uk-accordion-content">
                            <div className="uk-padding-small scaler-padding-small uk-padding-remove-top">
                                <fieldset className="uk-fieldset uk-form-stacked">
                                    <legend className="uk-legend uk-hidden">Headline Layout Settings</legend>
                                    <div className="uk-grid-small uk-child-width-1-1 scaler-grid" uk-grid>
                                        <div>
                                            <label htmlFor="layout-style" className="uk-form-label scaler-form-label uk-text-uppercase">Style</label>
                                            <div className="uk-form-controls">
                                                <select className="uk-select scaler-select uk-border-rounded" id="layout-style">
                                                    <option>Default Image Style</option>
                                                    <option>Alternate Custom Image Style</option>
                                                    <option>+ Add New Style</option>
                                                </select>
                                            </div>

                                            <div id="modal-add-style" className="uk-flex-top scaler-modal" uk-modal="true">
                                                <div className="uk-modal-dialog scaler-modal-dialog uk-margin-auto-vertical">
                                                    <button className="uk-modal-close-default" type="button" uk-close="true"></button>
                                                    <div className="uk-modal-header">
                                                        <h2 className="uk-modal-title uk-text-uppercase">Add New Image Style</h2>
                                                    </div>
                                                    <div className="uk-modal-body">
                                                        <fieldset className="uk-fieldset uk-form-stacked">
                                                            <legend className="uk-legend uk-hidden">Custom Image Styles</legend>

                                                            <div className="uk-grid-small uk-child-width-1-2 scaler-grid" uk-grid="true">

                                                                <div className="uk-width-1-1">
                                                                    <label htmlFor="custom-image-1-label" className="uk-form-label scaler-form-label uk-text-uppercase">Image Style Label</label>
                                                                    <div className="uk-form-controls">
                                                                        <input className="uk-input scaler-input uk-border-rounded" id="custom-image-1-label" type="text" />
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <label htmlFor="custom-image-1-shape" className="uk-form-label scaler-form-label uk-text-uppercase">Shape</label>
                                                                    <div className="uk-form-controls">
                                                                        <select className="uk-select scaler-select uk-border-rounded" id="custom-image-1-shape">
                                                                            <option selected="selected">Square</option>
                                                                            <option>Rounded Corners</option>
                                                                            <option>Pill-Shaped</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="uk-grid-margin uk-first-column">
                                                                    <label htmlFor="custom-image-1-shadow" className="uk-form-label scaler-form-label uk-text-uppercase">Box Shadow</label>
                                                                    <div className="uk-form-controls">
                                                                        <select className="uk-select scaler-select uk-border-rounded" id="custom-image-1-shadow">
                                                                            <option selected="selected">None</option>
                                                                            <option>Small</option>
                                                                            <option>Medium</option>
                                                                            <option>Large</option>
                                                                            <option>Extra Large</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="uk-width-1-1">
                                                                    <label htmlFor="custom-image-1-filter" className="uk-form-label scaler-form-label uk-text-uppercase">Filter</label>
                                                                    <div className="uk-form-controls">
                                                                        <select className="uk-select scaler-select uk-border-rounded" id="custom-image-1-filter">
                                                                            <option selected="selected">None</option>
                                                                            <option>Lighten</option>
                                                                            <option>Darken</option>
                                                                            <option>Sepia</option>
                                                                            <option>Grayscle</option>
                                                                            <option>Primary Color Blend (Multiply)</option>
                                                                            <option>Primary Color Blend (Overlay)</option>
                                                                            <option>Secondary Color Blend (Multiple)</option>
                                                                            <option>Secondary Color Blend (Overlay)</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="uk-width-1-1">
                                                                    <label htmlFor="custom-image-1-css" className="uk-form-label scaler-form-label uk-text-uppercase">Custom CSS / Sass</label>
                                                                    <div className="uk-form-controls">
                                                                        <textarea className="uk-textarea scaler-textarea uk-border-rounded" id="custom-image-1-css" rows="5"></textarea>
                                                                    </div>
                                                                </div>

                                                                <div className="uk-width-1-1">
                                                                    <label htmlFor="custom-image-1-hover">
                                                                        <span className="uk-form-label scaler-form-label uk-text-uppercase">Hover Styles</span>
                                                                        <div className="uk-form-control uk-switch">
                                                                            <input type="checkbox" id="custom-image-1-hover" uk-toggle="target: .scaler-custom-image-1-hover-toggle; animation: uk-animation-fade" />
                                                                            <div className="uk-switch-slider"></div>
                                                                        </div>
                                                                    </label>
                                                                </div>

                                                                <div className="scaler-custom-image-1-hover-toggle uk-width-1-1" hidden>
                                                                    <div className="uk-grid-small uk-child-width-1-1 scaler-grid" uk-grid="true">
                                                                        <div>
                                                                            <label htmlFor="custom-image-1-shadow-hover" className="uk-form-label scaler-form-label uk-text-uppercase">Box Shadow <span className="uk-text-light">(Hover State)</span></label>
                                                                            <div className="uk-form-controls">
                                                                                <select className="uk-select scaler-select uk-border-rounded" id="custom-image-1-shadow-hover">
                                                                                    <option selected="selected">None</option>
                                                                                    <option>Small</option>
                                                                                    <option>Medium</option>
                                                                                    <option>Large</option>
                                                                                    <option>Extra Large</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <label htmlFor="custom-image-1-filter-hover" className="uk-form-label scaler-form-label uk-text-uppercase">Filter <span className="uk-text-light">(Hover State)</span></label>
                                                                            <div className="uk-form-controls">
                                                                                <select className="uk-select scaler-select uk-border-rounded" id="custom-image-1-filter-hover">
                                                                                    <option selected="selected">None</option>
                                                                                    <option>Lighten</option>
                                                                                    <option>Darken</option>
                                                                                    <option>Sepia</option>
                                                                                    <option>Grayscle</option>
                                                                                    <option>Primary Color Blend (Multiply)</option>
                                                                                    <option>Primary Color Blend (Overlay)</option>
                                                                                    <option>Secondary Color Blend (Multiple)</option>
                                                                                    <option>Secondary Color Blend (Overlay)</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <label htmlFor="custom-image-1-css-hover" className="uk-form-label scaler-form-label uk-text-uppercase">Custom CSS / Sass <span className="uk-text-light">(Hover State)</span></label>
                                                                            <div className="uk-form-controls">
                                                                                <textarea className="uk-textarea scaler-textarea uk-border-rounded" id="custom-image-1-css-hover" rows="5"></textarea>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </fieldset>

                                                        <div className="uk-margin-medium-top uk-text-right">
                                                            <button className="uk-button uk-button-default scaler-button-default uk-border-rounded scaler-button-text uk-modal-close" type="button">Cancel</button>
                                                            <button className="uk-button uk-button-primary scaler-button-primary uk-border-rounded scaler-button-text uk-margin-small-left" type="button">Save</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div className="uk-grid-margin uk-first-column">
                                            <label htmlFor="layout-crop" className="uk-form-label scaler-form-label uk-text-uppercase">Crop</label>
                                            <div className="uk-form-controls">
                                                <div className="uk-button-group scaler-radio-icons uk-flex uk-flex-between">
                                                    <label htmlFor="image-crop-4-1" uk-toggle="cls: active" uk-tooltip="title: 4:1 Ratio; delay: 200"><span className="material-icons scaler-24 scaler-scale-y-80">crop_7_5</span></label>
                                                    <label htmlFor="image-crop-3-1" uk-toggle="cls: active" uk-tooltip="title: 3:1 Ratio; delay: 200"><span className="material-icons scaler-24">crop_7_5</span></label>
                                                    <label htmlFor="image-crop-7-3" uk-toggle="cls: active" uk-tooltip="title: 7:3 Ratio; delay: 200"><span className="material-icons scaler-24">crop_16_9</span></label>
                                                    <label htmlFor="image-crop-16-9" uk-toggle="cls: active" uk-tooltip="title: 16:9 Ratio; delay: 200"><span className="material-icons scaler-24">crop_5_4</span></label>
                                                    <label htmlFor="image-crop-4-3" uk-toggle="cls: active" uk-tooltip="title: 4:3 Ratio; delay: 200"><span className="material-icons scaler-24">crop_3_2</span></label>
                                                    <label htmlFor="image-crop-1-1" uk-toggle="cls: active" uk-tooltip="title: Square Ratio; delay: 200"><span className="material-icons scaler-24">crop_square</span></label>
                                                    <label htmlFor="image-crop-3-4" uk-toggle="cls: active" uk-tooltip="title: 3:4 Ratio; delay: 200"><span className="material-icons scaler-24 scaler-rotate-90">crop_3_2</span></label>
                                                    <label htmlFor="image-crop-9-16" uk-toggle="cls: active" uk-tooltip="title: 9:16 Ratio; delay: 200"><span className="material-icons scaler-24 scaler-rotate-90">crop_5_4</span></label>
                                                    <label htmlFor="image-crop-3-7" uk-toggle="cls: active" uk-tooltip="title: 3:7 Ratio; delay: 200"><span className="material-icons scaler-24 scaler-rotate-90">crop_16_9</span></label>
                                                    <input type="radio" id="image-crop-4-1" className="uk-hidden" value="3:1" />
                                                    <input type="radio" id="image-crop-4-1" className="uk-hidden" value="3:1" />
                                                    <input type="radio" id="image-crop-7-3" className="uk-hidden" value="7:3" />
                                                    <input type="radio" id="image-crop-16-9" className="uk-hidden" value="16:9" />
                                                    <input type="radio" id="image-crop-4-3" className="uk-hidden" value="4:3" />
                                                    <input type="radio" id="image-crop-1-1" className="uk-hidden" value="1:1" />
                                                    <input type="radio" id="image-crop-3-4" className="uk-hidden" value="3:4" />
                                                    <input type="radio" id="image-crop-9-16" className="uk-hidden" value="9:16" />
                                                    <input type="radio" id="image-crop-7-3" className="uk-hidden" value="7:3" />
                                                </div>
                                            </div>
                                        </div>

                                        <div  className="uk-grid-margin uk-first-column">
                                            <label htmlFor="layout-alignment" className="uk-form-label scaler-form-label uk-text-uppercase">Alignment</label>
                                            <div className="uk-form-controls">
                                                <div className="uk-button-group scaler-radio-icons">
                                                    <label htmlFor="layout-alignment-left" uk-toggle="cls: active"><span className="material-icons scaler-24">format_align_left</span></label>
                                                    <label htmlFor="layout-alignment-center" uk-toggle="cls: active"><span className="material-icons scaler-24">format_align_center</span></label>
                                                    <label fohtmlForr="layout-alignment-right" uk-toggle="cls: active"><span className="material-icons scaler-24">format_align_right</span></label>
                                                    <input type="radio" id="layout-alignment-left" className="uk-hidden" value="Left" />
                                                    <input type="radio" id="layout-alignment-center" className="uk-hidden" value="Center" />
                                                    <input type="radio" id="layout-alignment-right" className="uk-hidden" value="Right" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </li>
            </>
        )
    }
}
