import React, { Component } from 'react'

export default class content extends Component {
    render() {

        const { props }     = this
        const {items}       = props
        
        return (
            <>
                <li className="uk-open">
                        <a className="uk-accordion-title uk-padding-small scaler-padding-small uk-h4 uk-text-bold scaler-heading" href="/">Content</a>
                        <div className="uk-accordion-content">
                            <div className="uk-padding-small scaler-padding-small uk-padding-remove-top">
                                <fieldset className="uk-fieldset uk-form-stacked">
                                    <legend className="uk-legend uk-hidden">Headline Content Settings</legend>
                                    <div className="uk-grid-small uk-child-width-1-1@s scaler-grid" uk-grid="true">
                                        <div>
                                            <label htmlFor={"content-text"} className="uk-form-label scaler-form-label uk-text-uppercase">Text<small className="uk-float-right">14 characters, 3 words</small></label>
                                            <div className="uk-form-controls">
                                                <input className="uk-input scaler-input uk-border-rounded" id="content-text" type="text" defaultValue={"Let's do this -> Element ID "+items.element} />
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </li>
            </>
        )
    }
}
