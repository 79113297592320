import React, { Component } from 'react';
import Compiler from './compiler.jsx'

class Builder extends Component{ 

   render(){

      const {props} = this
      
      return(
        <Compiler {...props} />
      );
   }
}

export default Builder