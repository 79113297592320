import React, { Component } from 'react'

export default class Letter extends Component {

    render() {

        const { props }         = this
        const { spacing }       = props.styleJson.typography

        return (
            
            <li>
                <a className="uk-accordion-title uk-text-uppercase" href="/">{spacing && spacing.title}</a>
                <div className="uk-accordion-content">
                    <div className="uk-padding-small scaler-background-dark scaler-font-sizes">

                        <fieldset className="uk-fieldset">
                            <legend className="uk-legend uk-hidden">{spacing && spacing.title}</legend>
                            
                            { this.sizes( props ) }
                            
                        </fieldset>
                    </div>
                </div>
            </li>
        )
    }

    /**
     * get font size options
     * 
     * @param {object} props
     * 
     * @return {array} 
     */
    sizes(props)
    {

        const { spacing }        = props.styleJson.typography

        try {
            
            return spacing.group.map(( option, index ) => {

                return (

                    <div className="uk-flex uk-flex-middle uk-flex-between" key={index}>
                        <label htmlFor={"font-size-xsmall-rem"} className="uk-form-label scaler-form-label uk-text-uppercase">{option && option.title}</label>
                            
                            <div className="uk-form-controls uk-inline">
                                <span className="uk-form-icon scaler-form-icon uk-form-icon-flip">em</span>
                                <input data-type="em" onChange={(el) => this.handleEvent(el, option) } className="uk-input scaler-input uk-border-rounded" type="number" step={option && option.rem} value={option && option.em}/>
                            </div>
                            
                            <span data-type="spacing" onClick={(el) => this.handleEvent(el, option) } className="material-icons scaler-18">swap_horiz</span>
                            
                            <div className="uk-form-controls uk-inline">
                                <input data-type="emp" onChange={(el) => this.handleEvent(el, option) } className="uk-input scaler-input uk-border-rounded" type="number" step={option && option.px} value={option && option.emp}/>
                            </div>
                    </div>

                )
            })
        } catch (error) {
        }
    }

    /**
     * handle font size update
     * 
     * @param {object} el 
     * @param {array} option 
     * 
     * @return {*}
     */
    handleEvent(el, option)
    {
        const { props } = this
        props.handler.sizeEvent( el, option, props )
    }
}
