import { SAVE_SCALER_JSON, UPDATE_ACTIVE_SECTION, SWITCH_PANEL, STYLE_DATA } from './../actions/types'

/** database storage instance */
import Connection from './../../../models/storage'

/** common helper instance */
import Helper from './../../../component/helper'

/** constants instance */
import {constants} from './../../../component/constants'

/** event handling instance */
import Event from './../../event/'

/** storage class */
let localStorage  = new Connection()

/** event class */
let event         = new Event()

/** helper class */
let helper        = new Helper()

/** reduceer initial storage */
const initialState = {
  
  localStorage : localStorage,
  constants: constants,
  
  event : event,
  helper: helper,
  
  jsonData: localStorage.getJson(),
  styleJson: localStorage.getStyleJson(),
  
  items: {
    section: false,
    container: false,
    element: false,
    panel: constants.PANELS.GLOBAL
  }
}

export default function(state = initialState, actions){

  switch(actions.type){
    
    /** scaler json update */
    case SAVE_SCALER_JSON :
        
      return {
          ...state,
          jsonData: actions.payload
      }

     /** update event section */
     case UPDATE_ACTIVE_SECTION : 

        return {
          ...state,
          items: actions.payload
      }

    /** panel controler */
    case SWITCH_PANEL :

        return {
          ...state,
          panel: actions.payload
        }

    /** global style section */
    case STYLE_DATA :

        return {
          ...state,
          styleJson: actions.payload
        } 
        
    /** default action */
    default:
      return state
  }
}
