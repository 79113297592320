import React from 'react';

/**
 * left sidebar top buttons
 * 
 * @param {object} props 
 * 
 * @return {HTMLAttributes}
 */
export const button = (props) => (
    
    <div className="uk-flex uk-flex-between uk-margin">
        <div>
            <a 
                className="uk-button uk-button-default uk-button-small uk-border-rounded scaler-button-text scaler-button-default" 
                href="/" id={props.constants.PANELS.GLOBAL} onClick={(el) => props.handler.switchPanel(el, props) }>
                    Settings
            </a>
        </div>
        <div className="uk-flex">
            <button className="uk-button uk-button-secondary uk-button-small uk-border-rounded scaler-button-text scaler-button-secondary uk-margin-right">Save</button>
            <button className="uk-button uk-button-primary uk-button-small uk-border-rounded scaler-button-text scaler-button-primary">Publish</button>
        </div>
    </div>
)

/**
 * left sidebar top details panel
 * 
 * @param {object} props 
 * 
 * @return {HTMLAttributes}
 */

export const details = () => (
    <div className="uk-flex uk-flex-between uk-flex-middle">
        <div className="uk-h3 uk-text-bold uk-margin-remove scaler-heading">About Us</div>
        <ul className="uk-iconnav scaler-iconnav uk-flex-middle">
            <li className="uk-active"><a href="/"><span className="material-icons">desktop_mac</span></a></li>
            <li><a href="/"><span className="material-icons">laptop_mac</span></a></li>
            <li><a href="/"><span className="material-icons scaler-18">tablet_mac</span></a></li>
            <li><a href="/"><span className="material-icons scaler-18">phone_iphone</span></a></li>
        </ul>
    </div>
)

export const trash = () => (
    <div className="scaler-blueprint-delete-area uk-padding-small uk-box-shadow-medium uk-text-center uk-transition-slide-bottom">
        <div className="scaler-blueprint-delete-area uk-padding-small uk-box-shadow-medium uk-text-center uk-transition-slide-bottom">
            <div className="scaler-blueprint-delete-background uk-flex uk-flex-middle uk-flex-center">
                <span className="material-icons scaler-18">delete</span> DELETE
            </div>
            <div className="scaler-blueprint-delete-drop" uk-sortable="group: elements"></div>
        </div>
    </div>
)

