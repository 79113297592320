import React from 'react';

export const h1 = (text) => (
    <h1 className="uk-text-bold">{text}</h1>
)

export const h3 = (text) => (
    <h3 className="uk-text-bold uk-margin-small-top uk-margin-small-bottom">{text}</h3>
)

