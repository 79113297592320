import React, { Component } from 'react'

export default class image extends Component {
    render() {
        return (
            <div>
                <img className={this.props.cls} src={this.props.src} alt="Initial Discovery" />
            </div>
        )
    }
}
