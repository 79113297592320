import React, { Component } from 'react'
import Layout from './layout'

export default class Text extends Component {

    render() {

        const { props } = this

        return (
            <>
                <div className="scaler-sidebar-heading uk-padding-small scaler-padding-small uk-text-uppercase uk-flex uk-flex-middle uk-flex-between">
                    Text Settings
                    <a href="/" className="uk-flex uk-flex-middle"><span className="material-icons scaler-12">arrow_back_ios</span>
                        <small id={props.constants.PANELS.SCALER} onClick={(el) => props.handler.switchPanel(el, props) }>Page Blueprint</small>
                    </a>
                </div>
                <ul uk-accordion="true" className="scaler-accordion">

                     <Layout  {...props}/>
                </ul>
            </>
        )
    }
}
