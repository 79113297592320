import React from 'react';

const Footer = () => (
  
  <div id="offcanvas" uk-offcanvas="mode: push; overlay: true">
        <div className="uk-offcanvas-bar">
            <div className="uk-panel">

            </div>
        </div>
    </div>
);

export default Footer;
