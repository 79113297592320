import React from 'react';
import Route from './route'

/**
 * call all routes
 *  
 * @param {*} NA
 * 
 * @return {obejct} route
 */
const App = () => (
    <Route />
)

export default App;
