import React, { Component } from 'react'

export default class layout extends Component {
    render() {
        return (
            <>
                 <li>
                        <a className="uk-accordion-title uk-padding-small scaler-padding-small uk-h4 uk-text-bold scaler-heading" href="/">Layout</a>
                        <div className="uk-accordion-content">
                            <div className="uk-padding-small scaler-padding-small uk-padding-remove-top">
                                <fieldset className="uk-fieldset uk-form-stacked">
                                    <legend className="uk-legend uk-hidden">Headline Layout Settings</legend>
                                    <div className="uk-grid-small uk-child-width-1-1@s scaler-grid" uk-grid="true">
                                        <div>
                                            <label htmlFor={"layout-style"} className="uk-form-label scaler-form-label uk-text-uppercase">Style</label>
                                            <div className="uk-form-controls">
                                                <select className="uk-select scaler-select uk-border-rounded" id="layout-style">
                                                    <option>Default Headline</option>
                                                    <option>Alternate Headline</option>
                                                    <option>Add New Style</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor={"layout-size"} className="uk-form-label scaler-form-label uk-text-uppercase">Size</label>
                                            <div className="uk-form-controls">
                                                <select className="uk-select scaler-select uk-border-rounded" id="layout-size" defaultValue="Base">
                                                    <option>Extra Small</option>
                                                    <option>Small</option>
                                                    <option>Base</option>
                                                    <option>Large</option>
                                                    <option>Extra Large</option>
                                                    <option>2-XL</option>
                                                    <option>3-XL</option>
                                                    <option>4-XL</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor={"layout-alignment"} className="uk-form-label scaler-form-label uk-text-uppercase">Alignment</label>
                                            <div className="uk-form-controls">
                                                <div className="uk-button-group scaler-radio-icons">
                                                    <label htmlFor={"layout-alignment-left"} uk-toggle="cls: active"><span className="material-icons scaler-24">format_align_left</span></label>
                                                    <label htmlFor={"layout-alignment-center"} uk-toggle="cls: active"><span className="material-icons scaler-24">format_align_center</span></label>
                                                    <label htmlFor={"layout-alignment-right"} uk-toggle="cls: active"><span className="material-icons scaler-24">format_align_right</span></label>
                                                    <input type="radio" id="layout-alignment-left" className="uk-hidden" value="Left"/>
                                                    <input type="radio" id="layout-alignment-center" className="uk-hidden" value="Center"/>
                                                    <input type="radio" id="layout-alignment-right" className="uk-hidden" value="Right"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </li>
            </>
        )
    }
}
