import React, { Component } from 'react'

export default class Layout extends Component {
    render() {
        return (
            <>
                 <li>
                    <a className="uk-accordion-title uk-padding-small scaler-padding-small uk-h4 uk-text-bold scaler-heading" href="/">Layout</a>
                    <div className="uk-accordion-content">
                        <div className="uk-padding-small scaler-padding-small uk-padding-remove-top">
                            <fieldset className="uk-fieldset uk-form-stacked">
                                <legend className="uk-legend uk-hidden">Button Layout Settings</legend>
                                <div className="uk-grid-small uk-child-width-1-1 scaler-grid" uk-grid>
                                    <div>
                                        <label htmlFor="layout-style" className="uk-form-label scaler-form-label uk-text-uppercase">Style</label>
                                        <div className="uk-form-controls">
                                            <select className="uk-select scaler-select uk-border-rounded" id="layout-style">
                                                <option>Default Button Style</option>
                                                <option>Call to Action Button</option>
                                                <option>+ Add New Style</option>
                                            </select>
                                        </div>

                                        <div id="modal-add-style" className="uk-flex-top scaler-modal" uk-modal="true">
                                            <div className="uk-modal-dialog scaler-modal-dialog uk-margin-auto-vertical">
                                                <button className="uk-modal-close-default" type="button" uk-close="true"></button>
                                                <div className="uk-modal-header">
                                                    <h2 className="uk-modal-title uk-text-uppercase">Add New Button Style</h2>
                                                </div>
                                                <div className="uk-modal-body">
                                                    <fieldset className="uk-fieldset uk-form-stacked">
                                                        <legend className="uk-legend uk-hidden">Custom Headline Styles</legend>

                                                        <div className="uk-grid-small uk-child-width-1-2 scaler-grid" uk-grid="true">

                                                            <div className="uk-width-1-1">
                                                                <label htmlFor="custom-button-1-label" className="uk-form-label scaler-form-label uk-text-uppercase">Button Style Label</label>
                                                                <div className="uk-form-controls">
                                                                    <input className="uk-input scaler-input uk-border-rounded" id="custom-button-1-label" type="text" />
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <label htmlFor="custom-button-1-font-family" className="uk-form-label scaler-form-label uk-text-uppercase">Font Family</label>
                                                                <div className="uk-form-controls">
                                                                    <select className="uk-select scaler-select uk-border-rounded" id="custom-button-1-font-family">
                                                                        <option>Body</option>
                                                                        <option>Primary Display</option>
                                                                        <option selected="selected">Secondary Display</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <label htmlFor="custom-button-1-color" className="uk-form-label scaler-form-label uk-text-uppercase">Text Color</label>
                                                                <div className="uk-form-controls">
                                                                    <select className="uk-select scaler-select uk-border-rounded" id="custom-button-1-color">
                                                                        <option selected="selected">Primary Color</option>
                                                                        <option>Secondary Color</option>
                                                                        <option>Text Color</option>
                                                                        <option>Alert Color</option>
                                                                        <option>Warning Color</option>
                                                                        <option>Success Color</option>
                                                                        <option>Custom Color 1</option>
                                                                        <option>Custom Color 2</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <label htmlFor="custom-button-1-weight" className="uk-form-label scaler-form-label uk-text-uppercase">Font Weight</label>
                                                                <div className="uk-form-controls">
                                                                    <select className="uk-select scaler-select uk-border-rounded" id="custom-button-1-weight">
                                                                        <option>Light</option>
                                                                        <option selected="selected">Normal</option>
                                                                        <option>Bold</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <label htmlFor="custom-button-1-case" className="uk-form-label scaler-form-label uk-text-uppercase">Case</label>
                                                                <div className="uk-form-controls">
                                                                    <select className="uk-select scaler-select uk-border-rounded" id="custom-button-1-case">
                                                                        <option selected="selected">Normal</option>
                                                                        <option>Capitalize</option>
                                                                        <option>Uppercase</option>
                                                                        <option>Lowercase</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <label htmlFor="custom-button-1-tracking" className="uk-form-label scaler-form-label uk-text-uppercase">Letter Spacing</label>
                                                                <div className="uk-form-controls">
                                                                    <select className="uk-select scaler-select uk-border-rounded" id="custom-button-1-tracking">
                                                                        <option>Tighter</option>
                                                                        <option>Tight</option>
                                                                        <option selected="selected">Normal</option>
                                                                        <option>Wide</option>
                                                                        <option>Wider</option>
                                                                        <option>Widest</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <label htmlFor="custom-button-1-border-radius" className="uk-form-label scaler-form-label uk-text-uppercase">Shape</label>
                                                                <div className="uk-form-controls">
                                                                    <select className="uk-select scaler-select uk-border-rounded" id="custom-button-1-border-radius">
                                                                        <option selected="selected">Square</option>
                                                                        <option>Rounded Corners</option>
                                                                        <option>Pill-Shaped</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="uk-width-1-1">
                                                                <label htmlFor="custom-button-1-css" className="uk-form-label scaler-form-label uk-text-uppercase">Custom CSS / Sass</label>
                                                                <div className="uk-form-controls">
                                                                    <textarea className="uk-textarea scaler-textarea uk-border-rounded" id="custom-button-1-css" rows="5"></textarea>
                                                                </div>
                                                            </div>

                                                            <div className="uk-width-1-1">
                                                                <label htmlFor="custom-button-1-hover">
                                                                    <span className="uk-form-label scaler-form-label uk-text-uppercase">Hover Styles</span>
                                                                    <div className="uk-form-control uk-switch">
                                                                        <input type="checkbox" id="custom-button-1-hover" uk-toggle="target: .scaler-custom-button-1-hover-toggle; animation: uk-animation-fade" />
                                                                        <div className="uk-switch-slider"></div>
                                                                    </div>
                                                                </label>
                                                            </div>

                                                            <div className="scaler-custom-button-1-hover-toggle uk-width-1-1" hidden>
                                                                <div className="uk-grid-small uk-child-width-1-1 scaler-grid" uk-grid="true">
                                                                    <div>
                                                                        <label htmlFor="custom-button-1-color-hover" className="uk-form-label scaler-form-label uk-text-uppercase">Button Color <span className="uk-text-light">(Hover State)</span></label>
                                                                        <div className="uk-form-controls">
                                                                            <select className="uk-select scaler-select uk-border-rounded" id="custom-button-1-color-hover">
                                                                                <option selected="selected">Primary Color</option>
                                                                                <option>Secondary Color</option>
                                                                                <option>Text Color</option>
                                                                                <option>Alert Color</option>
                                                                                <option>Warning Color</option>
                                                                                <option>Success Color</option>
                                                                                <option>Custom Color 1</option>
                                                                                <option>Custom Color 2</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <label htmlFor="custom-button-1-css-hover" className="uk-form-label scaler-form-label uk-text-uppercase">Custom CSS / Sass <span className="uk-text-light">(Hover State)</span></label>
                                                                        <div className="uk-form-controls">
                                                                            <textarea className="uk-textarea scaler-textarea uk-border-rounded" id="custom-button-1-css-hover" rows="5"></textarea>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </fieldset>

                                                    <div className="uk-margin-medium-top uk-text-right">
                                                        <button className="uk-button uk-button-default scaler-button-default uk-border-rounded scaler-button-text uk-modal-close" type="button">Cancel</button>
                                                        <button className="uk-button uk-button-primary scaler-button-primary uk-border-rounded scaler-button-text uk-margin-small-left" type="button">Save</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="uk-grid-margin uk-first-column">
                                        <label htmlFor="layout-size" className="uk-form-label scaler-form-label uk-text-uppercase">Size</label>
                                        <div className="uk-form-controls">
                                            <select className="uk-select scaler-select uk-border-rounded" id="layout-size">
                                                <option>Small</option>
                                                <option selected>Default</option>
                                                <option>Large</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="uk-grid-margin uk-first-column">
                                        <label htmlFor="layout-width" className="uk-form-label scaler-form-label uk-text-uppercase">Width</label>
                                        <div className="uk-form-controls">
                                            <select className="uk-select scaler-select uk-border-rounded" id="layout-width">
                                                <option selected>Default</option>
                                                <option>Full-Width</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </li>
            </>
        )
    }
}
