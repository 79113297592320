import React, { Component } from 'react'

export default class custom extends Component {

    render() {

        const { props }     = this

        return (
            <div>
                <ul uk-accordion="true" className="scaler-accordion-secondary">
                    <li>
                        <a className="uk-accordion-title uk-text-uppercase" href="/">Custom Colors</a>
                        <div className="uk-accordion-content">
                            <div className="scaler-custom-colors">
                                
                                { this.customs( props )}

                            </div>
                        </div>
                    </li>
                </ul>
                <div className="scaler-blueprint-add uk-flex uk-flex-center uk-flex-middle">
                    <a href="/" data-type="add" className="scaler-blueprint-add-button uk-text-uppercase uk-border-rounded uk-flex uk-flex-middle" onClick={(el) => this.handleEvent(el, false)}>
                        <span className="material-icons scaler-16">add</span>
                        <span className="scaler-blueprint-element-label" data-type="add">Add New Color</span>
                    </a>
                </div>
            </div>
        )
    }

    /**
     * custom colors panel
     * 
     * @param {json} props 
     * 
     * @return {*}
     */
    customs( props )
    {
        const { color }     = props.styleJson
        const { group }     = color

        try {
            
            return (group.filter(list => !list.default )).map((option, index) => { 

                return (

                    <div className="uk-padding-small scaler-background-dark scaler-custom-color-card" key={index}>
                        <fieldset className="uk-fieldset uk-form-stacked">
                            <legend className="uk-legend uk-hidden">{ option && option.custom }</legend>
                                <div className="uk-grid-small uk-child-width-1-1@s scaler-grid" uk-grid="true">
                                <div>
                                    <label htmlFor="custom-color-1-name" className="uk-form-label scaler-form-label uk-text-uppercase">Color Name</label>
                                    <div className="uk-form-controls">
                                        <input className="uk-input scaler-input uk-border-rounded" data-type="title" onChange={(el) => this.handleEvent(el, option)} type="text" value={option.title} />
                                    </div>
                                </div>

                                <div className="uk-flex uk-flex-bottom uk-flex-between">
                                    <div className="scaler-color-input">
                                        <label htmlFor="custom-color-1-color" className="uk-form-label scaler-form-label uk-text-uppercase">Color</label>
                                        <div className="uk-form-controls uk-inline">
                                            <span className="uk-form-icon scaler-form-icon">{ color && color.sign }</span>
                                            <span className="uk-form-icon scaler-form-icon uk-form-icon-flip" style={{background: (color.sign+option.code)}}></span>
                                            <input className="uk-input scaler-input uk-border-rounded" data-type="code" type="color" value={color.sign+option.code} onChange={(el) => this.handleEvent(el, option)} />
                                        </div>
                                    </div>
                                    <a href="/" onClick={(el) => this.handleEvent(el, option)} className="scaler-delete-button uk-text-uppercase uk-border-rounded uk-flex uk-flex-middle" data-type="delete" onChange={(el) => this.handleEvent(el, option)}>
                                        <span className="material-icons scaler-16" data-type="delete">delete</span>
                                    </a>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                )
            })
        } catch (error) {
        }
    }

    /**
     * handle add new color, delete and update events
     * 
     * @param {object} el 
     * @param {object} option
     * 
     * @return {*}
     */
    handleEvent(el, color)
    {
        el.preventDefault()

        const { props } = this
        props.handler.colorEvent(el, color, props)
    }
}
