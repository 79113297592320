import React, { Component } from 'react'

export default class section extends Component {
    render() {
        return (
            <>
                <div className={this.props.cls}></div>
            </>
        )
    }
}
