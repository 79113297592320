import React, { Component } from 'react'

export default class advance extends Component {
    render() {
        return (
            <>
                <li>
                    <a className="uk-accordion-title uk-padding-small scaler-padding-small uk-h4 uk-text-bold scaler-heading " href="/">Advanced Options</a>
                    <div className="uk-accordion-content">
                        <div className="uk-padding-small scaler-padding-small uk-padding-remove-top">

                            <fieldset className="uk-fieldset uk-form-stacked">
                                <legend className="uk-legend uk-hidden">Image Advanced Options</legend>
                                <div className="uk-grid-small uk-child-width-1-1@s scaler-grid" uk-grid>

                                    <div>
                                        <label htmlFor="options-smart-sizing">
                                            <span className="uk-form-label scaler-form-label uk-text-uppercase">Smart Sizing</span>
                                            <div className="uk-form-control uk-switch">
                                                <input type="checkbox" id="options-smart-sizing" />
                                                <div className="uk-switch-slider"></div>
                                            </div>
                                        </label>
                                    </div>

                                </div>
                            </fieldset>
                        </div>
                    </div>
                </li>
            </>
        )
    }
}
