import React, { Component } from 'react'

export default class Custom extends Component {
    
    render() {

        const { props }   = this

        return this.preDefined( props )
    }

    /**
     * predefined headline panel
     * 
     * @param {object} props 
     * 
     * @return {*}
     */
    preDefined( props )
    {
        const { color }   = props.styleJson
        
        try {
            
            return props.group.map((option, index) => {
                
                return (
                    
                    <div className="uk-grid-small uk-child-width-1-2 scaler-grid" uk-grid="true" key={index}>
                        
                        <div>
                            <label htmlFor={"headline-color"} className="uk-form-label scaler-form-label uk-text-uppercase">Text Color</label>
                            <div className="uk-form-controls">
                                <select data-type="text" onChange={(el) => this.handleEvent(el, option)} className="uk-select scaler-select uk-border-rounded" value={option.text}>
                                    { this.dropdown( color.group ) }
                                </select>
                            </div>
                        </div>

                        <div>
                            <label htmlFor="button-hover">
                                <span className="uk-form-label scaler-form-label uk-text-uppercase">Hover Styles</span>
                                <div className="uk-form-control uk-switch">
                                    <input type="checkbox" id="button-hover" checked={option.underline} onChange={(el) => el.preventDefault()} uk-toggle={"target: .scaler-button-hover-toggle; animation: uk-animation-fade"} />
                                    <div data-type="underline" onClick={(el) => this.handleEvent(el, option)} className="uk-switch-slider"></div>
                                </div>
                            </label>
                        </div>

                        <div className="uk-width-1-1">
                            <label htmlFor={"headline-css"} className="uk-form-label scaler-form-label uk-text-uppercase">Custom CSS</label>
                            <div className="uk-form-controls">
                                <textarea data-type="css" onChange={(el) => this.handleEvent(el, option)} className="uk-textarea scaler-textarea uk-border-rounded" rows="5" value={option.css}></textarea>
                            </div>
                        </div>
                    </div>
                )
            }, this)
        } catch (error) {
        }
    }

    /**
     * headline handle event 
     * 
     * @param {object} el 
     * @param {object} option 
     * 
     * @return {*}
     */
    handleEvent(el, option)
    {
        el.preventDefault()
        
        const { props } = this
        props.handler.linkEvent( el, option, props );
    }

    /**
     * build dropdown for each element
     * 
     * @param {array} data 
     * 
     * @return {*}
     */
    dropdown(data)
    {
        try {
            
            return data.map(( option, index )  => {

                return (
                    <option value={option.id} key={index}> {option.title} </option>
                )
            })
        } catch (error) {
            
        }
    }
}
