import React, {Component} from 'react';
import { connect } from 'react-redux';

class Header extends Component { // eslint-disable-line react/prefer-stateless-function

  render() {

    return (

        <div uk-sticky="media: 960" className="uk-navbar-container scaler-navbar-container uk-sticky-fixed uk-background-secondary uk-light">
            <div className="uk-container uk-container-expand">
                <nav uk-navbar="true">
                    
                    <div className="uk-navbar-right">
                        <ul className="uk-navbar-nav uk-visible@m">
                            <li>
                                <a href="/" className="uk-navbar-item">
                                    <span className="material-icons">person</span>
                                </a>
                            </li>
                        </ul>
                        <div className="uk-navbar-item uk-visible@m"></div>
                        <a uk-navbar-toggle-icon="true" uk-toggle="true" href="#offcanvas" className="uk-navbar-toggle uk-hidden@m uk-icon uk-navbar-item"></a>
                    </div>
                </nav>
            </div>
        </div>
    );
  }
}

/** validate */
Header.propTypes = {
}

const mapStateToProps = state => ({
})

export default connect (mapStateToProps, { } )( Header )

