import React, { Component } from 'react';
import Button from './../button'

class Container extends Component{ 
    
    render(){

        const { attributes } = this.props.element

        return(
            <>
                {
                    ( attributes.id ) &&
                    
                    <div className="scaler-blueprint-section uk-border-rounded">
                        <div className="uk-sortable" uk-sortable="group: containers">
                            
                            {this.functionNode(attributes.id)}

                        </div>
                    </div>
                }
            </>
        );
    }

    /**
     * identify the contaienr by ID
     * 
     * @parma {string} functionString
     * 
     * @return {object} 
    */

    functionNode( functionString )
    {
        const { props } = this
        
        if ( typeof this[functionString] === "function" )  
            
            return this[functionString](props);
    }

    /**
     * Full width container
     * 
     * @parma {*} NA
     * 
     * @return {object} 
    */
    p3yfm15dcj = (props) => ( 


        <div className="scaler-blueprint-container-group" style={{"userSelect": "none"}}>

            <Button actionable={true} title={props.element.attributes.title} />

            <div className="uk-grid scaler-blueprint-grid" uk-height-match=".scaler-blueprint-sortable">
                <div className="uk-width-1-1">
                    <div className="scaler-blueprint-container uk-border-rounded">

                        { props.children }

                        <div className="uk-width-1-1">
                            <Button name={props.element.events.title} modal={props.element.events.modal} {...props} index="0"/>
                        </div>

                    </div>
                </div>  
            </div>
        </div>
    )

    /**
     * Halves container
     * 
     * @parma {*} NA
     * 
     * @return {object} 
     */
    awefelgyt4 = (props) => (

        <div className="scaler-blueprint-container-group" style={{"userSelect": "none"}}>

            <Button actionable={true} title={props.element.attributes.title} />

            <div className="uk-grid scaler-blueprint-grid" uk-height-match=".scaler-blueprint-sortable">

                {
                    props.constants.ELEMENTS.HALVES &&

                    props.constants.ELEMENTS.HALVES.map((elements, index) => {

                        return (
                            <div className="uk-width-1-2" key={index}>
                                <div className="scaler-blueprint-container uk-border-rounded">

                                    { props.children.filter(list => list.props.position === index )  }
                                    <Button name={props.element.events.title} modal={props.element.events.modal} {...props} index={index} />
                                </div>
                            </div>
                        )
                    })
                }
                
            </div>
        </div>
    )

    /**
     * Three section container
     * 
     * @parma {*} NA
     * 
     * @return {object} 
     */
    cn395iwlcq = () => (

        <div className="scaler-blueprint-container-group" style={{"userSelect": "none"}}>   
            <div className="uk-grid scaler-blueprint-grid" uk-height-match=".scaler-blueprint-sortable">
                <div className="uk-width-1-3">
                    <div className="scaler-blueprint-container uk-border-rounded">
                        <div className="scaler-blueprint-add uk-flex uk-flex-center uk-flex-middle">
                            <a href="/" className="scaler-blueprint-add-button uk-text-uppercase uk-border-rounded uk-flex uk-flex-middle"><span className="material-icons scaler-16">add</span><span className="scaler-blueprint-element-label">Add Element</span></a>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-3">
                    <div className="scaler-blueprint-container uk-border-rounded">
                        <div className="scaler-blueprint-add uk-flex uk-flex-center uk-flex-middle">
                            <a href="/" className="scaler-blueprint-add-button uk-text-uppercase uk-border-rounded uk-flex uk-flex-middle"><span className="material-icons scaler-16">add</span><span className="scaler-blueprint-element-label">Add Element</span></a>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-3">
                    <div className="scaler-blueprint-container uk-border-rounded">
                        <div className="scaler-blueprint-add uk-flex uk-flex-center uk-flex-middle">
                            <a href="/" className="scaler-blueprint-add-button uk-text-uppercase uk-border-rounded uk-flex uk-flex-middle"><span className="material-icons scaler-16">add</span><span className="scaler-blueprint-element-label">Add Element</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    /**
     * Third: 2 + 1 section container
     * 
     * @parma {*} NA
     * 
     * @return {object} 
     */
    g6v12lbi4x = () => (

        <div className="scaler-blueprint-container-group" style={{"userSelect": "none"}}>
            
            <div className="uk-grid scaler-blueprint-grid" uk-height-match=".scaler-blueprint-sortable">
                <div className="uk-width-2-3">
                    <div className="scaler-blueprint-container uk-border-rounded">
                        <div className="scaler-blueprint-add uk-flex uk-flex-center uk-flex-middle">
                            <a href="/" className="scaler-blueprint-add-button uk-text-uppercase uk-border-rounded uk-flex uk-flex-middle"><span className="material-icons scaler-16">add</span><span className="scaler-blueprint-element-label">Add Element</span></a>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-4">
                    <div className="scaler-blueprint-container uk-border-rounded">
                        <div className="scaler-blueprint-add uk-flex uk-flex-center uk-flex-middle">
                            <a href="/" className="scaler-blueprint-add-button uk-text-uppercase uk-border-rounded uk-flex uk-flex-middle"><span className="material-icons scaler-16">add</span><span className="scaler-blueprint-element-label">Add Element</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    /**
     * Third: 1 + 2 section container
     * 
     * @parma {*} NA
     * 
     * @return {object} 
     */
    u72mca6e7p = () => (

        <div className="scaler-blueprint-container-group" style={{"userSelect": "none"}}>
            
            <div className="uk-grid scaler-blueprint-grid" uk-height-match=".scaler-blueprint-sortable">
                <div className="uk-width-1-4">
                    <div className="scaler-blueprint-container uk-border-rounded">
                        <div className="scaler-blueprint-add uk-flex uk-flex-center uk-flex-middle">
                            <a href="/" className="scaler-blueprint-add-button uk-text-uppercase uk-border-rounded uk-flex uk-flex-middle"><span className="material-icons scaler-16">add</span><span className="scaler-blueprint-element-label">Add Element</span></a>
                        </div>
                    </div>
                </div>
                <div className="uk-width-2-3">
                    <div className="scaler-blueprint-container uk-border-rounded">
                        <div className="scaler-blueprint-add uk-flex uk-flex-center uk-flex-middle">
                            <a href="/" className="scaler-blueprint-add-button uk-text-uppercase uk-border-rounded uk-flex uk-flex-middle"><span className="material-icons scaler-16">add</span><span className="scaler-blueprint-element-label">Add Element</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    /**
     * Fourths section container
     * 
     * @parma {*} NA
     * 
     * @return {object} 
     */
    u8wght7qdp2 = (props) => (

        <div className="scaler-blueprint-container-group" style={{"userSelect": "none"}}>
            
            <Button actionable={true} title={props.element.attributes.title} />

            <div className="uk-grid scaler-blueprint-grid" uk-height-match=".scaler-blueprint-sortable">

                {
                    props.constants.ELEMENTS.FOURTH &&

                    props.constants.ELEMENTS.FOURTH.map((elements, index) => {

                        return (
                            <div className="uk-width-1-4" key={index}>
                                <div className="scaler-blueprint-container uk-border-rounded">

                                    { props.children.filter(list => list.props.position === index )  }
                                    <Button name={props.element.events.title} modal={props.element.events.modal} {...props} index={index} />
                                </div>
                            </div>
                        )
                    })
                }
                
            </div>
        </div>
    )
}

export default Container