import React, { Component } from 'react'

export default class link extends Component {
    render() {
        return (
            <>
                <a href={this.props.url} className={this.props.cls}>{this.props.content}</a>
            </>
        )
    }
}
