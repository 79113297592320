import React, { Component } from 'react';

/** Headline structure for scaler */
import Headline from './headline'

/** Text structure for scaler */
import Text from './text'

/** Image structure for scaler */
import Image from './image'

/** button structure for scaler */
import Button from './button'

class Index extends Component{ 

   render(){

      const { attributes } = this.props.element
      
      return this.functionNode(attributes.id)
   }

   /**
     * identify the contaienr by ID
     * 
     * @parma {string} functionString
     * 
     * @return {object} 
    */

   functionNode( functionString )
   {
       const { props } = this
       
       if ( typeof this[functionString] === "function" )  
           
           return this[functionString](props);

       return false
   }

   /**
     * call headline html structure
     * 
     * @parma {object} props
     * 
     * @return {object} 
    */
   w62f6ugs82(props)
   {
      return (
         <div className="uk-flex uk-flex-column scaler-blueprint-sortable uk-sortable" uk-sortable="group: elements; cls-custom: scaler-blueprint-element-drag">
            
            <Text {...props} ></Text>
         </div>
      )
   }

   /**
     * call text html structure
     * 
     * @parma {object} props
     * 
     * @return {object} 
    */
   mrosyz2bwf(props)
   {
      return (
         <div className="uk-flex uk-flex-column scaler-blueprint-sortable uk-sortable" uk-sortable="group: elements; cls-custom: scaler-blueprint-element-drag">
            
            <Headline {...props} ></Headline>
         </div>
      )
   }

   /**
     * call image html structure
     * 
     * @parma {object} props
     * 
     * @return {object} 
    */
   ivq2ptj202(props)
   {
      return (
         <div className="uk-flex uk-flex-column scaler-blueprint-sortable uk-sortable" uk-sortable="group: elements; cls-custom: scaler-blueprint-element-drag">
            
            <Image {...props} ></Image>
         </div>
      )
   }

   /**
     * call button html structure
     * 
     * @parma {object} props
     * 
     * @return {object} 
    */
   aybo13gg29(props)
   {
      return (
         <div className="uk-flex uk-flex-column scaler-blueprint-sortable uk-sortable" uk-sortable="group: elements; cls-custom: scaler-blueprint-element-drag">
            
            <Button {...props} ></Button>
         </div>
      )
   }
   
}
export default Index