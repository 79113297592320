import React, { Component } from 'react'

export default class advance extends Component {
    render() {
        return (
            <>
                  <li>
                        <a className="uk-accordion-title uk-padding-small scaler-padding-small uk-h4 uk-text-bold scaler-heading " href="/">Advanced Options</a>
                        <div className="uk-accordion-content">
                            <div className="uk-padding-small scaler-padding-small uk-padding-remove-top">

                                <fieldset className="uk-fieldset uk-form-stacked">
                                    <legend className="uk-legend uk-hidden">Headline Layout Settings</legend>
                                    <div className="uk-grid-small uk-child-width-1-1@s scaler-grid" uk-grid="true">
                                        <div>
                                            <div className="uk-grid-small" uk-grid="true">
                                                <div className="uk-width-1-4">

                                                    <label htmlFor={"options-link"}>
                                                        <span className="uk-form-label scaler-form-label uk-text-uppercase">Link</span>
                                                        <div className="uk-form-control uk-switch">
                                                            <input type="checkbox" id="options-link" uk-toggle="target: .scaler-options-link-toggle; animation: uk-animation-fade; queued: true"/>
                                                            <div className="uk-switch-slider"></div>
                                                        </div>
                                                    </label>

                                                </div>
                                                <div className="uk-width-3-4">

                                                    <div className="scaler-options-link-toggle" hidden>
                                                        <label htmlFor={"options-url"} className="uk-form-label scaler-form-label uk-text-uppercase">URL</label>
                                                        <div className="uk-form-controls">
                                                            <input className="uk-input scaler-input uk-border-rounded" id="options-url" type="text" defaultValue=""/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="scaler-options-link-toggle" hidden>

                                            <label htmlFor={"options-target"}>
                                                <span className="uk-form-label scaler-form-label uk-text-uppercase">Open in New Window</span>
                                                <div className="uk-form-control uk-switch">
                                                    <input type="checkbox" id="options-target"/>
                                                    <div className="uk-switch-slider"></div>
                                                </div>
                                            </label>

                                        </div>
                                        <div>
                                            <div className="uk-grid-small" uk-grid="true">
                                                <div className="uk-width-1-2">

                                                    <label htmlFor={"options-max-word-count"}>
                                                        <span className="uk-form-label scaler-form-label uk-text-uppercase">Max Word Count</span>
                                                        <div className="uk-form-control uk-switch">
                                                            <input type="checkbox" id="options-max-word-count"  uk-toggle="target: .scaler-options-max-word-count-toggle; animation: uk-animation-fade"/>
                                                            <div className="uk-switch-slider"></div>
                                                        </div>
                                                    </label>

                                                </div>
                                                <div className="uk-width-1-4">

                                                    <div className="scaler-options-max-word-count-toggle" hidden>
                                                        <label htmlFor={"options-max-word-suggest"} className="uk-form-label scaler-form-label uk-text-uppercase">Suggest</label>
                                                        <div className="uk-form-controls">
                                                            <input className="uk-input scaler-input uk-border-rounded" id="options-max-word-suggest" type="number" defaultValue=""/>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="uk-width-1-4">

                                                    <div className="scaler-options-max-word-count-toggle" hidden>
                                                        <label htmlFor={"options-max-word-cutoff"} className="uk-form-label scaler-form-label uk-text-uppercase">Cutoff</label>
                                                        <div className="uk-form-controls">
                                                            <input className="uk-input scaler-input uk-border-rounded" id="options-max-word-cutoff" type="number" defaultValue=""/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="uk-grid-small" uk-grid="true">
                                                <div className="uk-width-1-2">

                                                    <label htmlFor={"options-max-character-count"}>
                                                        <span className="uk-form-label scaler-form-label uk-text-uppercase">Max Character Count</span>
                                                        <div className="uk-form-control uk-switch">
                                                            <input type="checkbox" id="options-max-character-count"  uk-toggle="target: .scaler-options-max-character-count-toggle; animation: uk-animation-fade"/>
                                                            <div className="uk-switch-slider"></div>
                                                        </div>
                                                    </label>

                                                </div>
                                                <div className="uk-width-1-4">

                                                    <div className="scaler-options-max-character-count-toggle" hidden>
                                                        <label htmlFor={"options-max-character-suggest"} className="uk-form-label scaler-form-label uk-text-uppercase">Suggest</label>
                                                        <div className="uk-form-controls">
                                                            <input className="uk-input scaler-input uk-border-rounded" id="options-max-character-suggest" type="number" defaultValue=""/>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="uk-width-1-4">

                                                    <div className="scaler-options-max-character-count-toggle" hidden>
                                                        <label htmlFor={"options-max-character-cutoff"} className="uk-form-label scaler-form-label uk-text-uppercase">Cutoff</label>
                                                        <div className="uk-form-controls">
                                                            <input className="uk-input scaler-input uk-border-rounded" id="options-max-character-cutoff" type="number" defaultValue=""/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <label htmlFor={"options-smart-wrapping"}>
                                                <span className="uk-form-label scaler-form-label uk-text-uppercase">Smart Wrapping</span>
                                                <div className="uk-form-control uk-switch">
                                                    <input type="checkbox" id="options-smart-wrapping"/>
                                                    <div className="uk-switch-slider"></div>
                                                </div>
                                            </label>
                                        </div>

                                    </div>
                                </fieldset>

                            </div>
                        </div>
                    </li>
            </>
        )
    }
}
