import React, {Component} from 'react';
import {h1, h3} from './../../blocks/heading';
import Text from './../../blocks/text';
import Image from './../../blocks/image';
import Link from  './../../blocks/link';
import Section from './../../blocks/section';

class Editor extends Component { // eslint-disable-line react/prefer-stateless-function

  render() {
    return (
      <div className="scaler-main uk-width-expand uk-panel-scrollable scaler-panel-scrollable" uk-height-viewport="offset-top: true">
          <div className="uk-section uk-section-default uk-padding-large uk-text-center">
              <div className="uk-container">
                      { h1("Let's Do This.") }
                  <Text content="Just say go. Spire will implement the solutions you need to accomplish your goals." cls="uk-text-large uk-margin-large-bottom" />

              <div className="uk-child-width-1-1 uk-child-width-1-2@m uk-child-width-1-4@l" uk-grid="true">
                  <div>
                      <div>
                        <Image cls="uk-width-small" src="/upload/images/page-graphics/icons/spire-process01-discovery.png"/>
                      </div>
                      { h3() }
                      <Text content="Let’s get on the same page. Through a discovery process, we'll uncover your root challenges, learn more about your business, and clarify your target market." csl="uk-margin-small-top" />
                  </div>
                  <div>
                      <div>
                          <Image cls="uk-width-small" src="/upload/images/page-graphics/icons/spire-process02-plan.png" />
                      </div>
                      { h3() }
                      <Text content="Let’s get on the same page. Through a discovery process, we'll uncover your root challenges, learn more about your business, and clarify your target market." csl="uk-margin-small-top" />
                  </div>
                  <div>
                      <div>
                         <Image cls="uk-width-small" src="/upload/images/page-graphics/icons/spire-process03-project.png" />
                      </div>
                      { h3() }
                      <Text content="Let’s get on the same page. Through a discovery process, we'll uncover your root challenges, learn more about your business, and clarify your target market." csl="uk-margin-small-top" />
                  </div>
                  <div>
                      <div>
                         <Image cls="uk-width-small" src="/upload/images/page-graphics/icons/spire-process04-growth.png" />
                      </div>
                      { h3() }
                      <Text content="Let’s get on the same page. Through a discovery process, we'll uncover your root challenges, learn more about your business, and clarify your target market." csl="uk-margin-small-top" />
                  </div>
                </div>  
              </div>
          </div>
          <div className="uk-section uk-section-primary uk-padding uk-text-left">
              <div className="uk-container">
                  <div className="uk-child-width-1-2@s uk-flex-middle" uk-grid="true">
                       <div>
                            <Image cls="" src="/upload/images2020/graphics/how-can-we-help.png"/>
                       </div>
                       <div>
                           { h1("How can we help?") }
                           <Text content="If you have any questions, we’re here to help. Feel free to reach out below, OR you can grab a time that works best for you on our calendar." cls=""/>
                           <Link cls="uk-button uk-button-primary uk-button-large" url="#" content="Start Here"/>
                       </div>
                  </div>
              </div>
          </div>
          <Section cls="uk-section uk-section-secondary uk-padding uk-text-left" />
      </div>
    );
  }
}

export default Editor

