import { Component } from "react";
import { v4 as uuidv4 } from 'uuid';

export default class Index extends Component
{
    /**
     * default string generator 
     * 
     * @param {*} NA
     * 
     * @return {string} string
     */
    uuid()
    {
        try {

            return (uuidv4().replace(/-/g, "")).substr(5, 15);
            
        } catch (error) {
            
            return Date.now();
        }
    }

    /**
     * find data based on the given ID
     * 
     * @param {json} jsonData
     * @param {object} items
     * 
     * @return {string} string
     */
    filter(jsonData, items)
    {
        try {

            let results    = jsonData.filter( list => list.attributes.id  === items.section )

            return ( Array.isArray(results) ) ? results.values().next().value : results
            
        } catch (error) {

            return false
        }
    }

    /**
     * find array index
     * 
     * @param {array} results
     * 
     * @return {string} string
     */
    index( jsonData, item )
    {
        try {

            let results    = jsonData.findIndex( list => list.id  === item )

            return results
            
        } catch (error) {

            return false
        }
    }

    /**
     * default string generator 
     * 
     * @param {object} data
     * @param {object} el
     * 
     * @return {array} array
     */
    activeItem( data, el )
    {
        try {
            
            switch (data.element.type) { 

                case 'container':
                    
                    return {
                        section: data.id,
                        container: data.element.id,
                        element: false,
                        index: data.index,
                        panel: data.items.panel
                    }

                case 'section':
                    
                    return {
                        section: data.element.id,
                        container: false,
                        element: false,
                        index: data.index,
                        panel: data.items.panel
                    }
            
                default:
                    break;
            }
            
        } catch (error) {
            
            return Date.now();
        }
    }

    /**
     * first index of array
     * 
     * @param {array} dataArray
     * 
     * @return {*} 
     */
    firstIndex( dataArray )
    {
        return ( Array.isArray(dataArray) ) ? dataArray[0] : dataArray
    }
}