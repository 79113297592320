export const constants = {

    ELEMENTS: {
        FULLWIDTH: [0],
        HALVES: [0, 1],
        THREE: [0, 1, 2],
        FOURTH: [0, 1, 2, 3],
    },
    PANELS : {
        GLOBAL:     "up31u1ax3j",
        HEADLINE :  "qx7yqqyyf8",
        TEXTAREA:   "vpiuyo3nc7",
        IMAGE:      "g1py0qnnze",
        LINK:       "j40ksh1ktl",
        VIDEO:      "k5e66cwfx5",
        CODE:       "eucsznsgni",
        MAP :       "s61th0r402",
        SECTION:    "o0lm1hmem0",
        SCALER:     "vbay5229pw"
    }      
};
