import React, { Component } from 'react';
import Button from './../button'

class Section extends Component{ 

   /**
     * main section
     * 
     * @parma {*} NA
     * 
     * @return {object} 
    */
   render(){

        const { props }     = this
        
        return(
            <>
                <div className="scaler-blueprint-section-group">

                    <Button actionable={true} title={props.element.title} />
                    
                    <div className="scaler-blueprint-section uk-border-rounded">

                        { props.children }

                        <div className="uk-grid scaler-blueprint-grid" uk-height-match=".scaler-blueprint-sortable">
                        { 
                            props.element.events.children &&

                            <div className="uk-width-1-1">
                                <Button child={true} name={props.element.events.children.title} modal={props.element.events.children.modal} {...props} />
                            </div>
                        }
                        </div>

                    </div>
    
                </div>

                { 
                    props.element.events &&
                    <Button button={true} events={props.element.events} {...props}  test={true}/>
                }    
                
            </>

        );
   }
}
export default Section
