import React, { Component } from 'react'
import Custom from './../custom'

export default class Link extends Component {
    
    render() {

        const { props }       = this
        const { link }      = props.styleJson

        return (
            
            <li>
                <a className="uk-accordion-title uk-padding-small scaler-padding-small uk-h4 uk-text-bold scaler-heading" href="/">{link && link.title}</a>
                <div className="uk-accordion-content">
                    <div className="uk-padding-small scaler-padding-small uk-padding-remove-top">
                        
                        <p className="uk-margin-remove-top">{ link && link.description }</p>
                        <fieldset className="uk-fieldset uk-form-stacked">
                            <legend className="uk-legend uk-hidden">{link && link.title}</legend>
                            { this.globalPanel( props, false ) }
                        </fieldset>
                    </div>
                </div>
            </li>
        )
    }

    /**
     * child heading setting components
     * 
     * @param {object} props 
     * @param {boolean} type 
     * 
     * @return {*}
     */
    globalPanel( props, type ) 
    {
        const { link }   = props.styleJson

        return <Custom {...props} group={ link.group } />
    }
}
