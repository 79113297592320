import React, { Component } from 'react'

export default class global extends Component {
    
    render() {

        const { props }     = this
        
        return (
            <div>
                <fieldset className="uk-fieldset uk-form-stacked">
                    <legend className="uk-legend uk-hidden">{props.styleJson && props.styleJson.title}</legend>
                        <div className="uk-grid-small uk-child-width-1-2 scaler-grid" uk-grid="true">
                            
                            { this.preDefined(props) }
                        
                        </div>      
                </fieldset>
            </div>
        )
    }

    /**
     * predefined color panel
     * 
     * @param {json} props 
     * 
     * @return {*}
     */
    preDefined( props )
    {
        const { color }     = props.styleJson
        const { group }     = color

        try {
            
            return (group.filter(list => list.default)).map((option, index) => { 

                return (

                    <div className="scaler-color-input" key={index}>
                        <label htmlFor={"color-primary"} className="uk-form-label scaler-form-label uk-text-uppercase">{ option && option.title }</label>
                        <div className="uk-form-controls uk-inline">
                            <span className="uk-form-icon scaler-form-icon">{ color && color.sign }</span>
                            <span className="uk-form-icon scaler-form-icon uk-form-icon-flip" style={{background: color.sign+option.code}}></span>
                            
                            <input className="uk-input scaler-input uk-border-rounded" onChange={(el) => this.handleEvent(el, option)} type="color" value={color.sign+option.code}/>
                        </div>
                    </div>
                )

            })
        } catch (error) {
        }
    }
    
    /**
     * handle color change event
     * 
     * @param {object} el 
     * @param {object} color
     * 
     * @return {*}
     */
    async handleEvent(el, color)
    {
        const { props } = this

        color.code      = (el.target.value).replace(props.styleJson.color.sign, '')
        await props.handler.globalColor(color, props); 
    }
}
