import React, { Component } from 'react'

export default class Layout extends Component {
    render() {
        return (
            <>
                 <li className="uk-open">
                    <a className="uk-accordion-title uk-padding-small scaler-padding-small uk-h4 uk-text-bold scaler-heading" href="/">Content</a>
                    <div className="uk-accordion-content">
                        <div className="uk-padding-small scaler-padding-small uk-padding-remove-top">
                            <fieldset className="uk-fieldset uk-form-stacked">
                                <legend className="uk-legend uk-hidden">Text Content Settings</legend>
                                <div className="uk-grid-small uk-child-width-1-1@s scaler-grid" uk-grid="true">
                                    <div>
                                        <label htmlFor="content-text" className="uk-form-label scaler-form-label uk-text-uppercase">Text</label>
                                        <div className="uk-form-controls">
                                            <textarea className="uk-textarea scaler-textarea uk-border-rounded" id="content-text" rows="25">Just say go.  Spire will implement the solutions you need to accomplish your goals.</textarea>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </li>
            </>
        )
    }
}
