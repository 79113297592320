import Styles from './styles'
import UIkit from 'uikit';

class Event extends Styles
{   
    /**
     * add section on user event
     * 
     * @param {object} props 
     * 
     * @return {*}
    */
   async addSection( props )
    {
        const { localStorage, helper, items }   = props
        let defaultJson                         = localStorage.defaultJson().values().next().value
        let scalerJson                          = localStorage.getJson()

        defaultJson.id                  = helper.uuid()
        defaultJson.attributes.id       = helper.uuid()

        items.section                   = defaultJson.id

        scalerJson.push(defaultJson)
        
        await props.updateItems(items)
        await props.saveJson(scalerJson)
        await localStorage.saveJson(scalerJson)
    }

    /**
     * add container on user event
     * 
     * @param {object} props 
     * 
     * @return {*}
    */
   async addContainer( props, element )
   {

       const { items, helper, localStorage }  = props

       let containerJson    = localStorage.defaultContainerJson()
       let scalerJson       = localStorage.getJson()
       let index            = helper.index(scalerJson, items.section)

       try {
           
            containerJson.id                     = helper.uuid()
            containerJson.attributes.id          = element.id
            containerJson.attributes.title       = element.title
            containerJson.attributes.index       = items.index

            scalerJson[index].components.push(containerJson)
            await props.saveJson(scalerJson)

       } catch (error) { 
       
       }

        this.closeUiKitModal('#modal-add-container')
        await localStorage.saveJson(scalerJson)
   }

   /**
     * add element on user event
     * 
     * @param {object} props 
     * 
     * @return {*}
    */
   async addElement( props, element )
   {
       
        const { items, helper, localStorage }  = props

        let scalerJson       = localStorage.getJson()
        let elementJson      = localStorage.defaultElementJson()

        try {
          
            elementJson.id                     = helper.uuid()
            elementJson.attributes.id          = element.data.id
            elementJson.attributes.title       = element.data.text
            elementJson.attributes.index       = items.index
            elementJson.attributes.events      = null
            elementJson.attributes.components  = null

            let sectionIndex                     = helper.index(scalerJson, items.section)
            
            let containerIndex                   = helper.index(scalerJson[sectionIndex].components, items.container)

            scalerJson[sectionIndex].components[containerIndex].components.push(elementJson)
            await props.saveJson(scalerJson)

        } catch (error) {
          
        }

        this.closeUiKitModal('#modal-add-element')
        await localStorage.saveJson(scalerJson)
   }

    /**
     * pass event to redux action 
     * 
     * @param {object} props 
     * @param {object} Items
     * 
     * @return {*}
    */
   async updateItems( props, items )
   {
        await props.updateItems(items)
   }

   /**
     * close modal box on event
     * 
     * @param {string} modalName 
     * 
     * @return {*}
    */
   closeUiKitModal( modalName )
   {
        let modal = UIkit.modal( modalName );

        modal.toggle();
   }

   /**
     * switch panel based on the user event
     * 
     * @param {event} el
     * @param {object} props 
     * 
     * @return {*}
    */
   async switchPanel(el, props)
   {
        el.preventDefault()
        
        const {items}    = props

        let udpateItems  = {
               section: items.section,
               container: items.container,
               element: items.element,
               index: items.index,
               panel: (el) ? el.target.id : false
        }
        
        await props.updateItems(udpateItems)
   }
}

export default Event
