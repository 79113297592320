import React from 'react';
import { Provider } from 'react-redux';

/** redux store*/
import store from './../redux/store'

/** global react ruter dom component */
import { BrowserRouter, Switch, Route } from 'react-router-dom'

/** main page index */
import Index from './../app'

/** Uikit library inherite */
import './../../package/uikit'

/** custom styles */
import './../../styles/index.css'

/** global element styling  */
import './../../styles/element.css'

/** Headline Styling  */
import './../../styles/headline.css'

/**
 * all routes
 *  
 * @param {*} NA
 * 
 * @return {obejct} route
 */

const App = () => (
    <Provider store = { store } >
        <BrowserRouter>
            <Switch>
                <Route path="/" component={Index} exact={true} />
            </Switch>
        </BrowserRouter>
    </Provider >
);
  
export default App;