import React, { Component } from 'react'

export default class Image extends Component {
    render() {

        const {props}       = this
        const {element}     = props

        return (
            <>
                <div className="scaler-blueprint-element uk-border-rounded uk-flex-auto uk-flex uk-flex-center uk-flex-middle uk-text-uppercase scaler-blueprint-element-compact" onClick={(el) => this.handleEvent(el, element) }>
                    <span className="scaler-icon material-icons scaler-18">photo</span>
                    <span className="scaler-blueprint-element-label">Image</span>
                </div>
            </>
        )
    }

    /**
     * switch panel based on the user event
     * 
     * @param {event} el
     * @param {object} element 
     * 
     * @return {*}
    */

   async handleEvent(el, element)
   {
       const {props}       = this
       const { items }     = props

       let udpateItems  = {
           section: items.section,
           container: items.container,
           element: element.id,
           index: items.index,
           panel: props.constants.PANELS.IMAGE
       }
    
       await props.updateItems(udpateItems)
   }
}
