import React, { Component } from 'react';

class Button extends Component{ 

   /**
     * main section inherite child section
     * 
     * @parma {obejct} props
     * 
     * @return {object} 
    */
   
   render(){
      
        const { props } = this
        
        return(
            <>
            { 
                props.actionable && 
                
                this.actionable()
            }
            {
                props.button && 
                this.addButton()
            }
            {
                props.modal && 
                this.modalButton()
            }
            </>
        );
   }

   /**
     * actionable button for each container
     * 
     * @parma {*} NA
     * 
     * @return {object} 
   */
   actionable()
   {
       return (
            <div className="scaler-blueprint-label uk-text-uppercase uk-flex uk-flex-middle">
                <a href="/" className="scaler-blueprint-menu-button uk-button-default scaler-button-default uk-border-rounded" aria-expanded="false"><span className="material-icons scaler-16">more_horiz</span></a>
                <div uk-dropdown="mode: click" className="scaler-blueprint-dropdown uk-border-rounded uk-padding-small uk-dropdown uk-dropdown-bottom-left" style={{ "left": "0px", "top": "28px" }}>
                
                    <ul className="uk-nav uk-dropdown-nav scaler-dropdown-nav">
                        <li>
                            <a href="/" className="uk-flex uk-flex-middle">
                                <span className="material-icons scaler-16">create</span>
                                Edit
                            </a>
                        </li>
                        <li>
                            <a href="/" className="uk-flex uk-flex-middle">
                                <span className="material-icons scaler-16">content_copy</span>
                                Copy
                            </a>
                        </li>
                        <li>
                            <a href="/" className="uk-flex uk-flex-middle">
                                <span className="material-icons scaler-16">delete</span>
                                Delete
                            </a>
                        </li>
                    </ul>                   
                </div>
                
                {this.props.title}   
            </div>
       )
   }

   /**
     * add component button for each container
     * 
     * @parma {*} NA
     * 
     * @return {object} 
   */

   addButton()
   {
        const { props } = this

       return (

            <div className="scaler-blueprint-add uk-flex uk-flex-center uk-flex-middle" style={{"userSelect": "none"}}>
                <a href="/" className="scaler-blueprint-add-button uk-text-uppercase uk-border-rounded uk-flex uk-flex-middle" onClick={(el) => this.handleEvent(el, props)} >
                    <span className="material-icons scaler-16">add</span> {(props.events.name) && props.events.name}
                </a>
            </div>
       )
   }

   /**
     * modal button component for container
     * 
     * @parma {*} NA
     * 
     * @return {object} 
   */

  modalButton()
  {
        const { props } = this

        return (

            <div className="scaler-blueprint-add uk-flex uk-flex-center uk-flex-middle">
                <a href="/" uk-toggle={"target: #"+((props.modal) ? props.modal : false)} className="scaler-blueprint-add-button uk-text-uppercase uk-border-rounded uk-flex uk-flex-middle" onClick={(el) => this.handleEvent(el, props)}>
                    <span className="material-icons scaler-16">add</span>
                    <span className="scaler-blueprint-element-label">{(props.name) && props.name}</span>
                </a>
            </div>
        )
  }

  /**
   * handle events 
   * 
   * @param {*} el 
   * 
   * @param {*} events 
   */
  handleEvent(el, props)
  {
        el.preventDefault();

        try {
            
            const { handler, element, helper }     = props
            let items                              = helper.activeItem( props, el )
            
            let action                             = ( props.child ) ?  element.events.children.action : element.events.action
            
            if( action )
            {
                if ( typeof handler[action] === "function" )  
                
                    handler[action](props, items)
            }

        } catch (error) {
        }
  }
}
export default Button