import React, { Component } from 'react'
import Layer from './layer'

export default class Custom extends Component {
    
    render() {

        const { props }   = this

        return this.preDefined( props )
    }

    /**
     * predefined headline panel
     * 
     * @param {object} props 
     * 
     * @return {*}
     */
    preDefined( props )
    {
        const { typography, color, settings }   = props.styleJson
        const { types, line, spacing }          = typography
        const { weight, fontCase }              = settings
        
        try {
            
            return props.group.map((option, index) => { 

                return (

                    <Layer option={option} key={index} >
                       <div className="uk-grid-small uk-child-width-1-2 scaler-grid" uk-grid="true">
                            { 
                                option.custom && 

                                <div className="uk-width-1-1">
                                    <label htmlFor="custom-headline-1-label" className="uk-form-label scaler-form-label uk-text-uppercase">Headline Style Label</label>
                                    <div className="uk-form-controls">
                                        <input data-type="title" className="uk-input scaler-input uk-border-rounded" value={option.title} type="text" onChange={(el) => this.handleEvent(el, option)} />
                                    </div>
                                </div>
                            }
                            <div>
                                <label htmlFor={"headline-font-family"} className="uk-form-label scaler-form-label uk-text-uppercase">Font Family</label>
                                <div className="uk-form-controls">
                                    <select data-type="family" onChange={(el) => this.handleEvent(el, option)} className="uk-select scaler-select uk-border-rounded" value={option.family}>
                                        { this.dropdown(types) }
                                    </select>
                                </div>
                            </div>

                            <div>
                                <label htmlFor={"headline-color"} className="uk-form-label scaler-form-label uk-text-uppercase">Text Color</label>
                                <div className="uk-form-controls">
                                    <select data-type="text" onChange={(el) => this.handleEvent(el, option)} className="uk-select scaler-select uk-border-rounded" value={option.text}>
                                        { this.dropdown( color.group ) }
                                    </select>
                                </div>
                            </div>

                            <div>
                                <label htmlFor={"headline-weight"} className="uk-form-label scaler-form-label uk-text-uppercase">Font Weight</label>
                                <div className="uk-form-controls">
                                    <select data-type="weight" onChange={(el) => this.handleEvent(el, option)} className="uk-select scaler-select uk-border-rounded" value={option.weight}>
                                        { this.dropdown( weight ) }
                                    </select>
                                </div>
                            </div>

                            <div>
                                <label htmlFor={"headline-case"} className="uk-form-label scaler-form-label uk-text-uppercase">Case</label>
                                <div className="uk-form-controls">
                                    <select data-type="case" onChange={(el) => this.handleEvent(el, option)} className="uk-select scaler-select uk-border-rounded" value={option.case}>
                                        { this.dropdown( fontCase ) }
                                    </select>
                                </div>
                            </div>

                            <div>
                                <label htmlFor={"headline-leading"} className="uk-form-label scaler-form-label uk-text-uppercase">Line Height</label>
                                <div className="uk-form-controls">
                                    <select data-type="height" onChange={(el) => this.handleEvent(el, option)} className="uk-select scaler-select uk-border-rounded" value={option.height}>
                                        { this.dropdown( line.group ) }
                                    </select>
                                </div>
                            </div>

                            <div>
                                <label htmlFor={"headline-tracking"} className="uk-form-label scaler-form-label uk-text-uppercase">Letter Spacing</label>
                                <div className="uk-form-controls">
                                    <select data-type="spacing" onChange={(el) => this.handleEvent(el, option)} className="uk-select scaler-select uk-border-rounded" value={option.spacing}>
                                        { this.dropdown( spacing.group ) }
                                    </select>
                                </div>
                            </div>

                            <div className={ (option.custom) ? "uk-width-1-1 uk-grid-small uk-child-width-1-3 uk-flex uk-flex-between scaler-switch-row" : "uk-width-1-1 uk-grid-small uk-child-width-1-3 scaler-grid scaler-switch-row uk-grid uk-grid-margin uk-first-column" } uk-grid="true">
                                <div>
                                    <label htmlFor={"headline-italic"}>
                                        <span className="uk-form-label scaler-form-label uk-text-uppercase">Italic</span>
                                        <div className="uk-form-control uk-switch">
                                            <input type="checkbox" id={"headline-italic"+index} checked={option.italic} onChange={(el) => el.preventDefault()} />
                                            <div data-type="italic" onClick={(el) => this.handleEvent(el, option)} className="uk-switch-slider"></div>
                                        </div>
                                    </label>
                                </div>

                                <div>
                                    <label htmlFor={"headline-divider-line"}>
                                        <span className="uk-form-label scaler-form-label uk-text-uppercase">Divider Line</span>
                                        <div className="uk-form-control uk-switch">
                                            <input type="checkbox" id={"headline-divider-line"+index } checked={option.divider} onChange={(el) => el.preventDefault()} />
                                            <div data-type="divider" onClick={(el) => this.handleEvent(el, option)} className="uk-switch-slider"></div>
                                        </div>
                                    </label>
                                </div>

                                <div>
                                    <label htmlFor={"headline-line-modifier"}>
                                        <span className="uk-form-label scaler-form-label uk-text-uppercase">Line Modifier</span>
                                        <div className="uk-form-control uk-switch">
                                            <input type="checkbox" id={"headline-line-modifier"+index} checked={option.modifier} onChange={(el) => el.preventDefault()} />
                                            <div data-type="modifier" onClick={(el) => this.handleEvent(el, option)} className="uk-switch-slider"></div>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div className="uk-width-1-1">
                                <label htmlFor={"headline-css"} className="uk-form-label scaler-form-label uk-text-uppercase">Custom CSS</label>
                                <div className="uk-form-controls">
                                    <textarea data-type="css" onChange={(el) => this.handleEvent(el, option)} className="uk-textarea scaler-textarea uk-border-rounded" rows="5" value={option.css}></textarea>
                                </div>
                            </div>

                            { 
                                option.custom && 
                                <div className="uk-width-1-1">
                                    <a href="/" onClick={(el) => this.handleEvent(el, option) } data-type="delete" className="scaler-delete-button uk-text-uppercase uk-border-rounded uk-flex uk-flex-middle uk-float-right">
                                        <span className="material-icons scaler-16" data-type="delete">delete</span>
                                    </a>
                                </div>
                            }
                        </div>
                    </Layer>
                )
            }, this)
        } catch (error) {
        }
    }

    /**
     * headline handle event 
     * 
     * @param {object} el 
     * @param {object} option 
     * 
     * @return {*}
     */
    handleEvent(el, option)
    {
        el.preventDefault()
        
        const { props } = this
        props.handler.headlineEvent( el, option, props );
    }

    /**
     * build dropdown for each element
     * 
     * @param {array} data 
     * 
     * @return {*}
     */
    dropdown(data)
    {
        try {
            
            return data.map(( option, index )  => {

                return (
                    <option value={option.id} key={index}> {option.title} </option>
                )
            })
        } catch (error) {
            
        }
    }
}
