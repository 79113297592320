import {Component} from 'react'

class Styles extends Component
{   
    /**
     * global setting main hub
     * 
     * @param {object} color 
     * @param {object} props 
     * 
     * @return {*}
    */
   async globalColor( color, props )
   {
       try {
            
          const { helper, localStorage }    = props
          const styleJson                   = localStorage.getStyleJson()
          let index                         = helper.index(styleJson.color.group, color.id)

          styleJson.color.group[index].code  = color.code

          await props.stylePanel(styleJson)
          await localStorage.saveStyle(styleJson)

       } catch (error) {
       }
   }

   /**
     * color add, update and delete
     * 
     * @param {object} el
     * @param {object} color 
     * @param {object} props 
     * 
     * @return {*}
    */
   async colorEvent( el, color, props )
   {
        try {
             
          let key         = el.target.getAttribute('data-type')
          let index

          const { helper, localStorage }    = props
          const styleJson                   = localStorage.getStyleJson()
          
          switch (key) {

               case "add":

                         let colorJson       = {
                              id        : helper.uuid(),
                              default   : false,
                              title     : '',
                              code      : ''
                         }
                         styleJson.color.group.push(colorJson)

                    break;

               case "title":  
               
                         index   = helper.index(styleJson.color.group, color.id)
                         styleJson.color.group[index].title  = el.target.value
                    break;

               case "code":  
               
                         index   = helper.index(styleJson.color.group, color.id)
                         styleJson.color.group[index].code  = (el.target.value).replace(props.styleJson.color.sign, '')
                    break;

               case "delete":

                         index   = helper.index(styleJson.color.group, color.id)
                         styleJson.color.group.splice(parseInt(index), 1)
                    break; 
                    
               default:
                    break;
          } 

          await props.stylePanel(styleJson)
          await localStorage.saveStyle(styleJson)

        } catch (error) {

        }         
   }

   /**
     * family change event handle 
     * 
     * @param {object} el 
     * @param {array}  family
     * @param {object} props
     * 
     * @return {*}
    */
   async familyEvent(el, family, props)
   {
          const { localStorage, helper }     = props
          const styleJson                    = localStorage.getStyleJson()
          let index

          try {
               index   = helper.index(styleJson.typography.types, family.id)
               styleJson.typography.types[index].selected  = el.target.value

          } catch (error) {
               
          }
          await props.stylePanel(styleJson)
          await localStorage.saveStyle(styleJson)
   }

   /**
     * family change event handle 
     * 
     * @param {object} el 
     * @param {object} Size
     * @param {object} props
     * 
     * @return {*}
    */
   async sizeEvent(el, size, props)
   {
          let key                            = el.target.getAttribute('data-type')
          const { localStorage, helper }     = props
          const styleJson                    = localStorage.getStyleJson()
          let index
          
          try {

               switch (key) {

                    case "rem":  
                    case "px":
                    
                              index   = helper.index(styleJson.typography.sizes.group, size.id)
                              styleJson.typography.sizes.group[index][key]  = el.target.value
                         break;
     
                    case "sizes":
     
                              index   = helper.index(styleJson.typography[key].group, size.id)
                              styleJson.typography[key].group[index].px  = [styleJson.typography[key].group[index].rem, styleJson.typography[key].group[index].rem = styleJson.typography[key].group[index].px][0]
                         break; 

                    case "line":  
                    
                              index   = helper.index(styleJson.typography.line.group, size.id)
                              styleJson.typography.line.group[index].value  = el.target.value
                         break;

                    case "emp": 
                    case "em":
                    
                              index   = helper.index(styleJson.typography.spacing.group, size.id)
                              styleJson.typography.spacing.group[index][key]  = el.target.value
                         break;

                    case "spacing":
     
                              index   = helper.index(styleJson.typography[key].group, size.id)
                              styleJson.typography[key].group[index].emp  = [styleJson.typography[key].group[index].em, styleJson.typography[key].group[index].em = styleJson.typography[key].group[index].emp][0]
                         break; 

                    default:
                         break;

               } 
               
          } catch (error) {
          }
          await props.stylePanel(styleJson)
          await localStorage.saveStyle(styleJson)
   }

   /**
     * headline change event handle 
     * 
     * @param {object} el 
     * @param {object} Headline
     * @param {object} props
     * 
     * @return {*}
    */
   async headlineEvent(el, headline, props)
   {
          
          const { localStorage, helper }     = props
          let key                            = el.target.getAttribute('data-type')
          const styleJson                    = localStorage.getStyleJson()

          try {

               let index   = (headline) ? helper.index(styleJson.headline.group, headline.id) : false

               switch (key) {

                    case "divider":  
                    case "italic":
                    case "modifier":
                    
                              styleJson.headline.group[index][key]  = !headline[key]
                         break;

                    case "add":
                         
                         let alternate       = (localStorage.getStyleJson().headline.group).values().next().value
                         alternate.id        = helper.uuid()
                         alternate.custom    = true
                         
                         styleJson.headline.group.push(alternate)

                         break;

                    case "delete":

                              index   = helper.index(styleJson.headline.group, headline.id)
                              styleJson.headline.group.splice(parseInt(index), 1)
                         break; 

                    default:

                              styleJson.headline.group[index][key]  = el.target.value
                         break;
               }

          }catch (error) {
          }

          await props.stylePanel(styleJson)
          await localStorage.saveStyle(styleJson)
   }

   /**
     * buttons change event handle 
     * 
     * @param {object} el 
     * @param {object} button
     * @param {object} props
     * 
     * @return {*}
    */
   async buttonEvent(el, button, props)
   {
          
          const { localStorage, helper }     = props
          let key                            = el.target.getAttribute('data-type')
          const styleJson                    = localStorage.getStyleJson()

          try {

               let index   = (button) ? helper.index(styleJson.button.group, button.id) : false

               switch (key) {

                    case "hover":
                    
                              styleJson.button.group[index][key]  = !button[key]
                         break;

                    case "add":
                         
                         let alternate       = (localStorage.getStyleJson().button.group).values().next().value
                         alternate.id        = helper.uuid()
                         alternate.custom    = true
                         
                         styleJson.button.group.push(alternate)

                         break;

                    case "delete":

                              index   = helper.index(styleJson.button.group, button.id)
                              styleJson.button.group.splice(parseInt(index), 1)
                         break; 

                    default:

                              styleJson.button.group[index][key]  = el.target.value
                         break;
               }

          }catch (error) {
          }

          await props.stylePanel(styleJson)
          await localStorage.saveStyle(styleJson)
   }

   /**
     * image change event handle 
     * 
     * @param {object} el 
     * @param {object} button
     * @param {object} props
     * 
     * @return {*}
    */
   async imageEvent(el, image, props)
   {
          
          const { localStorage, helper }     = props
          let key                            = el.target.getAttribute('data-type')
          const styleJson                    = localStorage.getStyleJson()

          try {

               let index   = (image) ? helper.index(styleJson.image.group, image.id) : false

               switch (key) {

                    case "hover":
                    
                              styleJson.image.group[index][key]  = !image[key]
                         break;

                    case "add":
                         
                         let alternate       = (localStorage.getStyleJson().image.group).values().next().value
                         alternate.id        = helper.uuid()
                         alternate.custom    = true
                         
                         styleJson.image.group.push(alternate)

                         break;

                    case "delete":

                              index   = helper.index(styleJson.image.group, image.id)
                              styleJson.image.group.splice(parseInt(index), 1)
                         break; 

                    default:

                              styleJson.image.group[index][key]  = el.target.value
                         break;
               }

          }catch (error) {
          }

          await props.stylePanel(styleJson)
          await localStorage.saveStyle(styleJson)
   }

   /**
     * link change event handle 
     * 
     * @param {object} el 
     * @param {object} button
     * @param {object} props
     * 
     * @return {*}
    */
   async linkEvent(el, link, props)
   {
          
          const { localStorage, helper }     = props
          let key                            = el.target.getAttribute('data-type')
          const styleJson                    = localStorage.getStyleJson()

          try {

               let index   = (link) ? helper.index(styleJson.link.group, link.id) : false

               switch (key) {

                    case "underline":

                         styleJson.link.group[index][key]  = !link[key]
                         break;

                    default:

                         styleJson.link.group[index][key]  = el.target.value
                         break;
               }
          }catch (error) {
          }

          await props.stylePanel(styleJson)
          await localStorage.saveStyle(styleJson)
   }
}

export default Styles
