import React, {Component} from 'react';

import {button, details, trash} from './section'

/** container list  */
import ContainerModal from './../../../models/container'

/** element list */
import ElementModal from './../../../models/element'

/** main page blueprint */
import Builder from './../../../modules/planner/scaler/builder'

/** styles component */
import Styles from './../../structure/styles'

/** headline component */
import Headline from './../../structure/headline'

/** Text component */
import Text from './../../structure/text'

/** Button component */
import Link from './../../structure/link'

/** image component */
import Image from './../../structure/image'


class Scaler extends Component {

  render() {

    const {props}   = this
    
    return (

        <>
            <div className="uk-padding-small scaler-padding-small">
                
                { button( props ) }
                
                { details() }

            </div>

            {
               this.functionNode(props.items.panel)
            }
        </>
    );
  }

  /**
     * identify the contaienr by ID
     * 
     * @parma {string} functionString
     * 
     * @return {object} 
    */

   functionNode( functionString )
   {
       const { props } = this
       
       if ( typeof this[functionString] === "function" )  
           
           return this[functionString](props);
   }

   /**
     * scaler panel default load
     * 
     * @parma {object} props
     * 
     * @return {object} 
    */
   vbay5229pw(props)
   {
      return (
         
            <>
                <ContainerModal {...props} />
                <ElementModal {...props} />

                <div className="scaler-sidebar-heading uk-padding-small scaler-padding-small uk-text-uppercase">About Us</div>
                <div className="scaler-blueprint uk-padding-small scaler-padding-small">
                    <div uk-sortable="group: sections">
                        
                        <Builder {...props} />
                    </div>
                </div>
            
                {trash()}
            </>
      )
   }

   /**
     * Headline panel load
     * 
     * @parma {object} props
     * 
     * @return {object} 
    */
   qx7yqqyyf8(props)
   {
       return <Headline {...props} />
   }

   /**
     * text panel load
     * 
     * @parma {object} props
     * 
     * @return {object} 
    */
   vpiuyo3nc7(props)
   {
       return <Text {...props} />
   }

   /**
     * link panel load
     * 
     * @parma {object} props
     * 
     * @return {object} 
    */
   j40ksh1ktl(props)
   {
        return <Link {...props} />
   }

   /**
     * image panel load
     * 
     * @parma {object} props
     * 
     * @return {object} 
    */
   g1py0qnnze(props)
   {
       return <Image {...props} />
   }

   /**
     * global panel load
     * 
     * @parma {object} props
     * 
     * @return {object} 
    */
   up31u1ax3j(props)
   {
       return <Styles {...props} />
   }
}

export default Scaler

