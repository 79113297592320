import React, { Component } from 'react'
import Custom from './custom';
import Global from './global';

 class Color extends Component {

    render() {

        const { props }     = this
        const { color }     = props.styleJson

        return (

            <li>
                <a className="uk-accordion-title uk-padding-small scaler-padding-small uk-h4 uk-text-bold scaler-heading" href="/">{color && color.title}</a>
                <div className="uk-accordion-content">
                    <div className="uk-padding-small scaler-padding-small uk-padding-remove-top">
                        <p className="uk-margin-remove-top">{color && color.description}</p>
                        <div>
                            <Global {...props} />
                            <Custom {...props} />
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}

export default Color;
