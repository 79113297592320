const styles  = {
    title   : "Global Styles",
    back    : "Page Blueprint",
    color   : {
        title : "Colors",
        custom  : "Custom Colors",
        button : "Add New Color",
        open: ".scaler-accordion-secondary", 
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum turpis mauris, tempor vel tristique in, imperdiet at eros. Quisque sit amet elit sollicitudin, justo.",
        sign: "#",
        group : [
            {
                id      : "t6sc5h63kl",
                title   : "Primary Color",
                code    : "1e87f0", 
                default : true
            },
            {
                id      : "vo8zsr9i4j",
                title   : "Secondary Color",
                code    : "222222", 
                default : true
            },
            {
                id      : "fr38po7449",
                title   : "Text Color",
                code    : "666666", 
                default : true
            },
            {
                id      : "umk3dhqspf",
                title   : "Alert Color",
                code    : "f0506e", 
                default : true
            },
            {
                id      : "5yzvnnm6x9",
                title   : "Warning Color",
                code    : "faa05a", 
                default : true
            },
            {
                id      : "6ge4ert44f",
                title   : "Success Color",
                code    : "32d296", 
                default : true
            },
            {
                id      : "rd5hlyitu5",
                title   : "Logo Navy Blue",
                code    : "384e84", 
                default : false
            },
            {
                id      : "u85p6yxap9",
                title   : "Logo Yellow",
                code    : "EEF52A", 
                default : false
            }
        ],
        isOpen : false,
    },

    typography   : {
        title : "Typography",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum turpis mauris, tempor vel tristique in, imperdiet at eros. Quisque sit amet elit sollicitudin, justo.",
        family  : [
           {
              label : "Roboto",
              value  : "5vqdb7lqv2" 
           },
           {
              label : "Roboto Slab",
              value  : "yv9p0lzrxu" 
           },
           {
              label : "Open Sans",
              value  : "ywzg6wq8zq" 
           },
           {
              label : "Lato",
              value  : "2a8t68ekmm" 
           },
           {
              label : "Montserrat",
              value  : "ccsg6awt4b" 
           },
           {
              label : "Antic Slab",
              value  : "t1api5ov1u" 
           },
           {
              label : "Oswald",
              value  : "fgvo08buzc" 
           },
           {
              label : "Raleway",
              value  : "ju9gio2z0l" 
           },
           {
              label : "Playfair Display",
              value  : "sa1iuri8u2" 
           },
           {
              label : "Quicksand",
              value  : "ln578am0qk" 
           },
           {
              label : "Crimson Text",
              value  : "r38ywx6fb9" 
           },
           {
              label : "Josefin Sans",
              value  : "bgfb52es71" 
           },
           {
              label : "Bitter",
              value  : "tblqc4kyp9" 
           },
           {
              label : "Bebas Neue",
              value  : "4h9wbz88b6" 
           }
        ],
        types   : [
            {
                id          : "h2ysp9b21g",
                title       : "BODY FONT FAMILY",
                selected    : "5vqdb7lqv2",
                type        : "body"
            },
            {
                id          : "0jlkrm13zm",
                title       : "PRIMARY DISPLAY FONT FAMILY",
                selected    : "5vqdb7lqv2",
                type        : "primary"
            },
            {
                id          : "ldhyn2ryic",
                title       : "SECONDARY DISPLAY FONT FAMILY",
                selected    : "5vqdb7lqv2",
                type        : "secondary"
            }
        ],
        sizes        : {
            title   : "Font Sizes",
            description : "",
            group   : [
                {
                   title    : "X-Small",
                   rem      : "0.75",
                   px       : "12",
                   id       : "b227vkcymh"
                },
                {
                    title    : "Small",
                    rem      : "0.875",
                    px       : "14",
                    id       : "8eh9chzye0"
                },
                {
                    title    : "Base",
                    rem      : "1",
                    px       : "16",
                    id       : "r92zd90ld6"
                },
                {
                    title    : "Large",
                    rem      : "1.125",
                    px       : "18",
                    id       : "glrfl7f1di"
                },
                {
                    title    : "X-Large",
                    rem      : "1.25",
                    px       : "20",
                    id       : "iynsy1hft5"
                },
                {
                    title    : "2-XL",
                    rem      : "1.5",
                    px       : "14",
                    id       : "untrq0a7oh"
                },
                {
                    title    : "3-XL",
                    rem      : "1.875",
                    px       : "30",
                    id       : "43hbj94kf2"
                },
                {
                    title    : "4-XL",
                    rem      : "2.25",
                    px       : "36",
                    id       : "1gmd84dvs0"
                }
            ]
        },
        line  : {
            title   : "Line Heights (Leading)",
            group   : [
                {
                    title   : "None",
                    value    : "1",
                    id      : "17gfrwsy9a"
                },
                {
                    title   : "Tight",
                    value    : "1.25",
                    id      : "394r4l9jdk"
                },
                {
                    title   : "Snug",
                    value    : "1.375",
                    id      : "9macz7t1os"
                },
                {
                    title   : "Normal",
                    value    : "1.5",
                    id      : "75xmzrz2tq"
                },
                {
                    title   : "Relaxed",
                    value    : "1.625",
                    id      : "h6r35gxml4"
                },
                {
                    title   : "Loose",
                    value    : "2",
                    id      : "ku85mput6f"
                }
            ]
        },
        spacing     : {
            title   : "Letter Spacing (Tracking)",
            group   : [
                {
                    title   : "TIGHTER",
                    em      : "-0.05",
                    emp     : "-50",
                    id      : "a7l68mcqgt"
                },
                {
                    title   : "TIGHT",
                    em      : "-0.025",
                    emp     : "-25",
                    id      : "buprpxnfid"
                },
                {
                    title   : "NORMAL",
                    em      : "0",
                    emp     : "0",
                    id      : "r4av456i0a"
                },
                {
                    title   : "WIDE",
                    em      : "0.025",
                    emp     : "25",
                    id      : "xar4js0e9h"
                },
                {
                    title   : "WIDER",
                    em      : "0.05",
                    emp     : "50",
                    id      : "qv6q0cah07"
                },
                {
                    title   : "WIDEST",
                    em      : "0.1",
                    emp     : "100",
                    id      : "8kqzbl29vr"
                }
            ]
        }
    },
    settings    : {

        weight  : [
            {
                id      : "vs2um3s25a",
                title   : "Light"
            },
            {
                id      : "98fxtjw3vi",
                title   : "Normal"
            },
            {
                id      : "r6yet44mhd",
                title   : "Bold"
            }
        ],
        fontCase  : [
            {
                id      : "lczdk717fc",
                title   : "Normal"
            },
            {
                id      : "8r5aslh6ec",
                title   : "Capitalize"
            },
            {
                id      : "fg1bcr8moq",
                title   : "Uppercase"
            },
            {
                id      : "5tw0c0j1hs",
                title   : "Lowercase"
            }
        ],
        shape   :[
            {
                id      : "owk6kuzjhh",
                title   : "Square"
            },
            {
                id      : "1z7wzyfcdh",
                title   : "Rounded Corners"
            },
            {
                id      : "vy7cb53bje",
                title   : "Pill-Shaped"
            }
        ],
        shadow   :[
            {
                id      : "0eahg23yw3",
                title   : "None"
            },
            {
                id      : "9z5yq5fyea",
                title   : "Small"
            },
            {
                id      : "5mpa4by81r",
                title   : "Medium"
            },
            {
                id      : "03tfg9obwb",
                title   : "Large"
            },
            {
                id      : "3n5daxdqx5",
                title   : "Extra Large"
            }
        ],
        filter   :[
            {
                id      : "8gtse9s7bk",
                title   : "None"
            },
            {
                id      : "w1pa102fjg",
                title   : "Lighten"
            },
            {
                id      : "4r7qkc9dcq",
                title   : "Darken"
            },
            {
                id      : "qio1d43u3k",
                title   : "Sepia"
            },
            {
                id      : "karuhdix3h",
                title   : "Grayscle"
            },
            {
                id      : "dpkwsgvmal",
                title   : "Primary Color Blend (Multiply)"
            },
            {
                id      : "737sfoe3lo",
                title   : "Primary Color Blend (Overlay)"
            },
            {
                id      : "chefqnycjj",
                title   : "Secondary Color Blend (Multiple)"
            },
            {
                id      : "gchh4ya3tq",
                title   : "Secondary Color Blend (Overlay)"
            }
        ]
    },
    headline : {
        title : "Headlines",
        custom  : "Custom Headline Style",
        button : "Add New Headline Style",
        description : "Set the default styles for the Headline element. You can also create additional custom headline styles that can be selected when adding a Headline in the Page Builder.",
        group : [
            {
                id      : "ingfbbaty0",
                title   : "Default Headline",
                family  : "h2ysp9b21g",
                text    : "t6sc5h63kl",
                weight  : "98fxtjw3vi",
                case    : "8r5aslh6ec",
                height  : "75xmzrz2tq",
                spacing : "r4av456i0a",
                italic  : true,
                divider : false,
                modifier: false,
                css     : false,
                custom  : false  
            },
            {
                id      : "as8xwxolng",
                title   : "Custom Headline Styles",
                family  : "h2ysp9b21g",
                text    : "t6sc5h63kl",
                weight  : "98fxtjw3vi",
                case    : "8r5aslh6ec",
                height  : "75xmzrz2tq",
                spacing : "r4av456i0a",
                italic  : false,
                divider : false,
                modifier: false,
                css     : false,
                custom  : true   
            }
        ]
    },
    button : {
        title : "Buttons",
        custom  : "Custom Buttons Style",
        button : "Add New Button Style",
        hover   : "Hover Styles",
        description : "Set the default styles for the Button element. You can also create additional custom button styles that can be selected when adding a Button in the Page Builder.",
        group : [
            {
                id          : "ingfbbaty0",
                title       : "Default Button",
                family      : "h2ysp9b21g",
                text        : "t6sc5h63kl",
                weight      : "98fxtjw3vi",
                case        : "8r5aslh6ec",
                shape       : "1z7wzyfcdh",
                spacing     : "r4av456i0a",
                css         : "",
                hover       : false,
                hoverColor  : "t6sc5h63kl",
                hoverCss    : "",
                custom      : false  
            },
            {
                id          : "as8xwxolng",
                title       : "Custom Button Styles",
                family      : "h2ysp9b21g",
                text        : "t6sc5h63kl",
                weight      : "98fxtjw3vi",
                case        : "8r5aslh6ec",
                shape       : "1z7wzyfcdh",
                spacing     : "r4av456i0a",
                css         : "",
                hover       : false,
                hoverColor  : "t6sc5h63kl",
                hoverCss    : "",
                custom  : true   
            }
        ]
    },
    image : {
        title : "Images",
        custom  : "Custom Image Style",
        button : "Add New Image Style",
        hover   : "Hover Styles",
        description : "Set the default styles for the Image element. You can also create additional custom image styles that can be selected when adding an Image in the Page Builder.",
        group : [
            {
                id          : "9rouob0o8x",
                title       : "Default Image",
                shape       : "1z7wzyfcdh",
                shadow      : "8r5aslh6ec",
                filter      : "r4av456i0a",
                css         : "",
                hover       : false,
                hoverShadow : "t6sc5h63kl",
                hoverFilter : "t6sc5h63kl",
                hoverCss    : "",
                custom      : false  
            },
            {
                id          : "qot0ak4wh6",
                title       : "Custom Image Style",
                shape       : "1z7wzyfcdh",
                shadow      : "8r5aslh6ec",
                filter      : "r4av456i0a",
                css         : "",
                hover       : false,
                hoverShadow : "t6sc5h63kl",
                hoverFilter : "t6sc5h63kl",
                hoverCss    : "",
                custom      : true   
            }
        ]
    },
    link : {
        title : "Links",
        description : "Set the default styles for links. These styles will be applied whenever a hyperlink is created within a Text Area element.",
        group : [
            {
                id          : "txvdl48hx5",
                title       : "Default Image",
                color       : "vo8zsr9i4j",
                underline   : false,
                css         : ""
            }
        ]
    }  
}

export default styles