import React, { Component } from 'react';
import Container from './containers'

class Index extends Component{ 

   render(){

      const { props }   = this
      
      return(
         <>
            {
               ( props.element && props.element.attributes ) &&
                  
                  <Container {...props} />
            }
         </>
      );
   }
   
}
export default Index