import React, { Component } from 'react'

export default class Line extends Component {

    render() {

        const { props }         = this
        const { line }          = props.styleJson.typography

        return (
            
            <li>
                <a className="uk-accordion-title uk-text-uppercase" href="/">{line && line.title}</a>
                <div className="uk-accordion-content">
                    <div className="uk-padding-small scaler-background-dark scaler-line-heights">

                        <fieldset className="uk-fieldset">
                            <legend className="uk-legend uk-hidden">{line && line.title}</legend>
                            
                            { this.sizes( props ) }
                            
                        </fieldset>
                    </div>
                </div>
            </li>
        )
    }

    /**
     * get font size options
     * 
     * @param {object} props
     * 
     * @return {array} 
     */
    sizes(props)
    {

        const { line }        = props.styleJson.typography

        try {
            
            return line.group.map(( option, index ) => {

                return (

                    <div className="uk-flex uk-flex-middle uk-flex-between" key={index}>
                        <label htmlFor="leading-none" className="uk-form-label scaler-form-label uk-text-uppercase">{option && option.title}</label>
                        <div className="uk-form-controls uk-inline">
                            <input onChange={(el) => this.handleEvent( el, option )} className="uk-input scaler-input uk-border-rounded" data-type="line" type="number" step="0.125" value={option && option.value} />
                        </div>
                    </div>

                )
            })
        } catch (error) {
        }
    }

    /**
     * handle font size update
     * 
     * @param {object} el 
     * @param {array} option 
     * 
     * @return {*}
     */
    handleEvent(el, option)
    {
        const { props } = this
        props.handler.sizeEvent( el, option, props )
    }
}
