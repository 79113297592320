import React, { Component } from 'react'
import Custom from './../custom'

export default class Image extends Component {
    
    render() {

        const { props }     = this
        const { image }     = props.styleJson

        return (
            
            <li>
                <a className="uk-accordion-title uk-padding-small scaler-padding-small uk-h4 uk-text-bold scaler-heading" href="/">{image && image.title}</a>
                <div className="uk-accordion-content">
                    <div className="uk-padding-small scaler-padding-small uk-padding-remove-top">
                        
                        <p className="uk-margin-remove-top">{ image && image.description }</p>
                        <fieldset className="uk-fieldset uk-form-stacked">
                            <legend className="uk-legend uk-hidden">{image && image.title}</legend>
                            { this.globalPanel( props, false ) }
                        </fieldset>

                        <ul uk-accordion="multiple: true" className="scaler-accordion-secondary">
                            { this.globalPanel( props, true ) }
                        </ul>

                        <div className="scaler-blueprint-add uk-flex uk-flex-center uk-flex-middle">
                            <a href="/" onClick={(el) => this.handleEvent(el) } data-type="add" className="scaler-blueprint-add-button uk-text-uppercase uk-border-rounded uk-flex uk-flex-middle">
                                <span className="material-icons scaler-16">add</span>
                                <span data-type="add" className="scaler-blueprint-element-label">{image && image.button}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </li>
        )
    }

    /**
     * child heading setting components
     * 
     * @param {object} props 
     * @param {boolean} type 
     * 
     * @return {*}
     */
    globalPanel( props, type ) 
    {
        const { image }   = props.styleJson

        const group   = image.group.filter( list => list.custom === type )

        return <Custom {...props} group={ group } />
    }
    
    /**
     * headline handle event 
     * 
     * @param {object} props 
     * @param {boolean} type 
     * 
     * @return {*}
     */
    handleEvent(el)
    {
        el.preventDefault()

        const { props } = this
        props.handler.imageEvent( el, false, props );
    }
}
