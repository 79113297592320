import React, { Component } from 'react';
import Section from './../../../component/structure/section'
import Container from './../../../component/structure/container'
import Element from './../../../component/structure/element'

class Compiler extends Component{ 

   render(){
      
        const scalerJson  = this.props.jsonData

        return this.structure( scalerJson )
   }


   /**
    * bind component structure using json
    * 
    * @param {json} scalerJson
    *  
    * @return {htmlobject} 
    */
   structure( scalerJson ) 
   {
       const { props } = this

        try {

           return scalerJson.map((element, index) => {
                
                return (
                    <>
                    { 
                        
                        <Section element={element} key={index} {...props} >

                            { element.attributes.id && 
                            
                               this.container( element )
                            }

                        </Section>
                    }
                    </>
                )

            }, this);

        } catch (error) {
            console.log(error, 'error');
        }   
   }

   /**
    * bind component contianer structure using json
    * 
    * @param {object} containers
    * 
    * @return {object} 
    */
   container( containers ) 
   {
        const { props } = this

        try {

           return containers.components.map((element, index) => {
                
                return (
                    <>
                    { 
                        
                        <Container id={containers.id} element={element}  key={index} {...props} >

                            { element.attributes.id && 
                            
                                this.elements( element )
                            }

                        </Container>
                    }
                    </>
                )

            }, this);

        } catch (error) {
            
        }   
   }

   /**
    * bind component elements structure using json
    * 
    * @param {object} elements
    * 
    * @return {object} 
    */
   elements( elements ) 
   {
        const { props } = this

        try {

           return (elements.components.filter(list => list.attributes.id )).map((element, index) => {
        
                return <Element id={elements.id} element={element} key={index} position={element.attributes.index} {...props} />
            });

        } catch (error) {
            
        }   
   }
}
export default Compiler