import React, { Component } from 'react'

export default class Family extends Component {
    
    render() {

        const { props }                     = this
        const { typography }                = props.styleJson
        const { types}                      = typography
        
        return (
            <div>
                <fieldset className="uk-fieldset uk-form-stacked">
                    <legend className="uk-legend uk-hidden">Global Typography Styles</legend>
                    <div className="uk-grid-small uk-child-width-1-1 scaler-grid" uk-grid="true">

                        { this.dropdown( types )}
                        
                    </div>
                </fieldset>
            </div>
        )
    }
    
    /**
     * get font family dropdown
     * 
     * @param {array} types 
     * 
     * @return {*}
     */
    dropdown( types )
    {
        try {
            return types.map(( option, index ) => {
            
                return (

                    <div key={index}>
                        <label htmlFor={"font-family-body"} className="uk-form-label scaler-form-label uk-text-uppercase">{option.title}</label>
                        <div className="uk-form-controls">
                            <select onChange ={(el) => this.handleEvent(el, option)} className="uk-select scaler-select uk-border-rounded" value={option.selected}>
                                { this.options() }      
                            </select>
                        </div>
                    </div>
                )
            }, this)
        } catch (error) {
            
        }
    }

    /**
     * get font family options
     * 
     * @param {*} NA
     * 
     * @return {array} 
     */
    options()
    {
        const { props }         = this
        const { family }        = props.styleJson.typography

        try {
            
            return family.map(( option, index ) => {

                return <option value={option.value} key={index}> {option.label} </option>
            })
        } catch (error) {
            
        }
    }

    /**
     * handle font family change event
     * 
     * @param {object} el 
     * 
     * @return {*}
     */
    handleEvent(el, option)
    {
        const { props } = this
        props.handler.familyEvent( el, option, props )
    }
}
