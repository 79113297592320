import React, { Component } from 'react'

export default class content extends Component {
    render() {

        return (
            <>
                <li className="uk-open">
                    <a className="uk-accordion-title uk-padding-small scaler-padding-small uk-h4 uk-text-bold scaler-heading" href="/">Content</a>
                    <div className="uk-accordion-content">
                        <div className="uk-padding-small scaler-padding-small uk-padding-remove-top">
                            <fieldset className="uk-fieldset uk-form-stacked">
                                <legend className="uk-legend uk-hidden">Image Content Settings</legend>
                                <div className="uk-grid-small uk-child-width-1-1@s scaler-grid" uk-grid="true">

                                    <div>
                                        <label htmlFor="content-text" className="uk-form-label scaler-form-label uk-text-uppercase">Image URL</label>
                                        <div className="uk-form-controls uk-inline uk-width-1-1">
                                            <button href="/" className="uk-form-icon scaler-form-icon uk-form-icon-flip js-upload" uk-form-custom="true" uk-icon="icon: cloud-upload">
                                                <input type="file" />
                                            </button>

                                            <input className="uk-input scaler-input uk-border-rounded" id="image-url" type="text" value="/upload/icons/initial-discovery.png" />

                                            <progress id="js-progressbar" className="uk-progress" value="0" max="100" hidden></progress>

                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </li>
            </>
        )
    }
}
