const scalerJson  = [

    {
        id: "w34p18c1mw",
        type : "section",
        title : "Section: Title",
        attributes :  {
            id : "r4a82teh4v",
        },
        components : [  
            {
                id: false,
                type : "container",
                parent : false,
                attributes :  {
                    id : false,
                    title: false,
                    index: false
                },
                components : [
                    {
                        id: false,
                        type : "element",
                        parent : false,
                        attributes :  {
                            id : false,
                            title: false,
                            index: false
                        },
                        components : false,
                        styles : {},
                        events : {
                            type : false,
                            title: false,
                            block :  false,
                            modal: false,
                            action : "updateItems"
                        }
                    }
                ],
                styles : {},
                events : {
                    type : "model",
                    title: "Add Element",
                    block :  false,
                    modal: "modal-add-element",
                    action : "updateItems"
                }
            }
        ],
        styles : {},
        events : {
           type : "button",
           name: "Add Section",
           action : "addSection",
           children : {
                type : "model",
                title: "Add Container",
                block :  false,
                modal: "modal-add-container",
                action : "updateItems"
           }
        }
    }
]

export default scalerJson