import React, { Component } from 'react';
import Button from './button'

class Index extends Component{ 

   /**
     * main section inherite child section
     * 
     * @parma {obejct} props
     * 
     * @return {object} 
    */
   render(){
      
        const { props } = this

        return(
            <Button {...props} />
        );
   }
}
export default Index