import React, { Component } from 'react'
import Setting from './setting';

 class Index extends Component {

    render() {

        const { props }     = this

        return (

            <Setting {...props} />
        );
    }
}

export default Index; 
