import {Component} from 'react';
import scalerJson from '../scaler/model/scalerJson';
import stylesJson from '../settings/model/settings';

export default class Index extends Component {

    constructor(props)
    {
        super(props)
        
        // localStorage.setItem('scalerJson', (localStorage.getItem('scalerJson') ? localStorage.getItem('scalerJson') : JSON.stringify(scalerJson)))
        localStorage.setItem('scalerJson', JSON.stringify(scalerJson))
        localStorage.setItem('stylesJson', JSON.stringify(stylesJson))
    }

    saveJson(scalerJsonData)
    {
        localStorage.setItem('scalerJson', JSON.stringify(scalerJsonData))
    }

    saveStyle(styleJsonData)
    {
        localStorage.setItem('stylesJson', JSON.stringify(styleJsonData))
    }

    getJson()
    {
        return JSON.parse(localStorage.getItem('scalerJson'))
    }

    getStyleJson()
    {
        return JSON.parse(localStorage.getItem('stylesJson'))
    }

    defaultJson()
    {
        return scalerJson
    }

    defaultContainerJson()
    {
        let results     =  JSON.parse(localStorage.getItem('scalerJson'))
        let section     = ( Array.isArray(results) ) ? results.values().next().value : results

        return ( Array.isArray(section.components) ) ? (section.components).values().next().value : section.components
    }

    defaultElementJson()
    {
        let results     =  JSON.parse(localStorage.getItem('scalerJson'))
        let section     = ( Array.isArray(results) ) ? results.values().next().value : results

        let container   = ( section ) ? (section.components).values().next().value : section.components

        return ( Array.isArray(section.components) ) ? (container.components).values().next().value : container.components
    }
}