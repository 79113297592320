import React from 'react';
import Route from './modules/routes/'


/**
 * call parent route 
 *  
 * @param {*} NA
 * 
 * @return {obejct} route
 */
const App = () => (
  <Route />
) 

export default App;
