import React, { Component } from 'react'

export default class content extends Component {
    render() {
        
        return (
            <>
                <li className="uk-open">
                    <a className="uk-accordion-title uk-padding-small scaler-padding-small uk-h4 uk-text-bold scaler-heading" href="/">Content</a>
                    <div className="uk-accordion-content">
                        <div className="uk-padding-small scaler-padding-small uk-padding-remove-top">
                            <fieldset className="uk-fieldset uk-form-stacked">
                                <legend className="uk-legend uk-hidden">Button Content Settings</legend>
                                <div className="uk-grid-small uk-child-width-1-1@s scaler-grid" uk-grid="true">

                                    <div>
                                        <label htmlFor="content-text" className="uk-form-label scaler-form-label uk-text-uppercase">Text</label>
                                        <div className="uk-form-controls">
                                            <input className="uk-input scaler-input uk-border-rounded" id="content-text" type="text" value="Start Here" />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="content-url" className="uk-form-label scaler-form-label uk-text-uppercase">URL</label>
                                        <div className="uk-form-controls">
                                            <input className="uk-input scaler-input uk-border-rounded" id="content-url" type="text" value="/start-here" />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="options-target">
                                            <span className="uk-form-label scaler-form-label uk-text-uppercase">Open in New Window</span>
                                            <div className="uk-form-control uk-switch">
                                                <input type="checkbox" id="options-target" />
                                                <div className="uk-switch-slider"></div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </li>
            </>
        )
    }
}
