import { SAVE_SCALER_JSON, UPDATE_ACTIVE_SECTION, SWITCH_PANEL, STYLE_DATA } from './types'

/**
 * saving data into database
 * 
 * @param {object} data 
 * 
 * @return {*}
 */
export const saveJson = (data) => dispatch => {

    dispatch({
        type: SAVE_SCALER_JSON,
        payload: data
    })
}

/**
 * update active items on user action
 * 
 * @param {object} data 
 * 
 * @return {*}
 */
export const updateItems = (data) => dispatch => {

    dispatch({
        type: UPDATE_ACTIVE_SECTION,
        payload: data
    })
}

/**
 * switch panel 
 * 
 * @param {object} data 
 * 
 * @return {*}
 */
export const switchPanel = (data)   => dispatch => {

    dispatch({
        type: SWITCH_PANEL,
        payload: data
    })
}

/**
 * style panel 
 * 
 * @param {object} data 
 * 
 * @return {*}
 */
export const stylePanel = (data)   => dispatch => {

    dispatch({
        type: STYLE_DATA,
        payload: data
    })
}
