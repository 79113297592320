import React, { Component } from 'react'

export default class Layer extends Component {
    
    render() {

        const { props }   = this

        return ( props.option.custom ) ? this.custom( props ) : this.defined( props ) 
    }


    defined( props )
    {
        return props.children
    }

    custom ( props )
    {
        return (
            <li key={ props.option.id }>
                <a className="uk-accordion-title uk-text-uppercase" href="/"> { props.option.title}</a>
                <div className="uk-accordion-content">
                    <div className="scaler-custom-headlines">
                        <div className="uk-padding-small scaler-background-dark scaler-custom-headline-card">

                            <fieldset className="uk-fieldset uk-form-stacked">
                                <legend className="uk-legend uk-hidden">Custom Headline Styles</legend>
                                { props.children }
                            </fieldset>
                        </div>
                    </div>
                </div>
            </li>
        )
    }

}
