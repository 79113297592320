import React, { Component } from 'react'

export default class text extends Component {
    render() {
        return (
            <>
                <p className={this.props.cls}>{this.props.content}</p>
            </>
        )
    }
}
