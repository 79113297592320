import React, { Component } from 'react'

export default class Button extends Component {

    render() {

        const {props}       = this
        const {element}     = props

        return (
        
            <div className="scaler-blueprint-element uk-border-rounded uk-flex-auto uk-flex uk-flex-center uk-flex-middle uk-text-uppercase" onClick={(el) => this.handleEvent(el, element) }>
                <span className="scaler-icon material-icons scaler-18">crop_7_5</span>
                <span className="scaler-blueprint-element-label">Button</span>
            </div>
        )
    }

    /**
     * switch panel based on the user event
     * 
     * @param {event} el
     * @param {object} element 
     * 
     * @return {*}
    */

   async handleEvent(el, element)
   {
       const {props}       = this
       const { items }     = props

       let udpateItems  = {
           section: items.section,
           container: items.container,
           element: element.id,
           index: items.index,
           panel: props.constants.PANELS.LINK
       }
    
       await props.updateItems(udpateItems)
   }
}
