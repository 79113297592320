import React, { Component } from 'react'
import Font from './font'
import Size from './size'
import Line from './line'
import Letter from './letter'

export default class Index extends Component {
    
    render() {

        const { props }         = this
        const { typography }    = props.styleJson

        return (
            <li>
                <a className="uk-accordion-title uk-padding-small scaler-padding-small uk-h4 uk-text-bold scaler-heading" href="/">{ typography && typography.title}</a>
                <div className="uk-accordion-content">
                    <div className="uk-padding-small scaler-padding-small uk-padding-remove-top">
                        <p className="uk-margin-remove-top">{ typography && typography.description }</p>
                        
                        <Font {...props} />

                        <ul uk-accordion="multiple: true" className="scaler-accordion-secondary uk-margin-remove-bottom">

                            <Size {...props} /> 
                            <Line {...props} /> 
                            <Letter {...props} /> 

                        </ul>

                    </div>
                </div>    
            </li>
        )
    }
}
